import "../components.css";
import "react-toastify/dist/ReactToastify.css";

import { Box, Stack } from '@mui/material';
import { Flex, Layout, Menu, Upload, theme } from 'antd';
import { NavLink, Route, Switch, } from 'react-router-dom';
import React, { useEffect, useRef, useState } from 'react';

import ArchiveIcon from '@mui/icons-material/Archive';
import BarChartIcon from '@mui/icons-material/BarChart';
import Button from '@mui/material/Button';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import ChatBot from "./ChatBot";
import Chip from '@mui/material/Chip';
import CompetitionForm from "../CompetitionForm.js";
import DataArchive from "./DataArchive";
import DriverAnalytics from "./DriverAnalytics";
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FormControl from '@mui/material/FormControl';
import GraphTelemetry from "./GraphTelemetry";
import GroupsIcon from '@mui/icons-material/Groups';
import HeroSectionExchangeData from '../HeroSectionExchangeData.js';
import HttpProvider from 'services/HttpProvider'
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import InsertDriver from "./InsertDriver.js";
import { LineChart } from '@mui/x-charts/LineChart';
import ManagerForm from "../ManagerForm.js";
import MenuIcon from '@mui/icons-material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { MenuOutlined } from '@ant-design/icons';
import { NoLuggageOutlined } from "@mui/icons-material";
import ParentRaceManager from "./ParentRaceManager";
import Pricing from "./Pricing.js";
import PropTypes from 'prop-types';
import Racingdata from "./Racingdata.js";
import Settings from './Settings.js';
import Settings1 from './Settings1.js';
import SettingsIcon from '@mui/icons-material/Settings';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import SportsMotorsportsIcon from '@mui/icons-material/SportsMotorsports';
import TelemetryParent from "./TelemetryParent";
import Telemetrydata from "./Telemetrydata";
import Telemetrytable from "./Telemetrytable";
import TrackMap from "./TrackMap";
import Typography from '@mui/material/Typography';
import UploadTelemetry from './UploadTelemetry'
import UserPlan from "./UserPlan.js";
import axios from 'axios';
import corniceEngine from './CorniceEngine.js';
import { createTheme } from '@mui/material/styles';
import dayjs from "dayjs";
import myUserStore from "../../stores/UserStore.js";
import { observer } from "mobx-react-lite"
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import FilesRecap from 'components/FilesRecap/Recap.js';

const { Header, Content, Footer, Sider } = Layout;

const itemHeight = 48;
const itemPaddingTop = 8;
const menuProps = {
  PaperProps: {
    style: {
      maxHeight: itemHeight * 4.5 + itemPaddingTop,
      width: 250,
    },
  },
};

const theme1 = createTheme({
  palette: {
    gold: {
      main: '#DAA520',
    },
  },
});













function getStyles(name, personName, theme) { // Pass 'name' and 'personName' as arguments
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}




function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}


const RacingSpace = ({ isLoggedIn }) => {

  const [isMobile, setIsMobile] = useState(false);
  const history = useHistory();
  const [click, setClick] = useState(false);
  const [button, setButton] = useState(true);
  const [parsedUserData, setParsedUserData] = useState((JSON.parse(localStorage.getItem("user_data"))) ? JSON.parse(localStorage.getItem("user_data")) : "");
  const [personalTeam, SetPersonalTeam] = useState([]);
  const [openPrice, setOpenPrice] = useState(false)
  const [showTeams, setShowTeams] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [myTeams, setMyTeams] = useState([])
  const [showHover, setShowHover] = useState(false);
  //const selectedTeam = JSON.parse(localStorage.getItem("selectedTeam")|| "{}");
  const selectedTeam = myUserStore.getSelectedTeam;
  //const [selectedTeam, setSelectedTeam] = useState(myUserStore.getSelectedTeam)

  //const showPersonalButton = Boolean(selectedTeam.name.startsWith('personal-'));
  const showPersonalButton = myUserStore.isPersonalSpace;
  console.log(selectedTeam)
  console.log("team name", selectedTeam.name)
  const subDetails = myUserStore?.subscriptionDetails
  console.log("my sub details are", subDetails)



  const getSubscription = async () => {
    try {
      const res = await HttpProvider.get(`/get_subscription/`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            accept: "application/json",
          },
        }
      )
        .then((res) => {

          const { subscriptionDetails = {} } = res.data
          const subId = subscriptionDetails.id
          myUserStore.setUserSubscriptionDetails.subscriptionDetails(res.data)
          return subscriptionDetails
        })
    } catch (error) {
      console.error("Error fetching data:", error);
      return false;
    }
  };





  //if(selectedTeam == '[]'){

  //}

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Adjust breakpoint as needed
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);






  const logOut = () => {
    localStorage.removeItem("access_token");
    localStorage.removeItem("user_data");
    myUserStore.setIsLoggedIn(false);
    history.replace("/sign-in");
    setClick(false);
  };
  const handleSettingsClick = (key) => {

    console.log(`Clicked on item with key ${key}`);
    setIsSettingsClicked(true)
    setIsDriverDataClicked(false);
    setIsAllDataClickedTelemetry(false)
    setIsDriverFormClicked(false);
    setIsCompetitionFormClicked(false);
    setIsManagerFormClicked(false);
    setIsAllDataClicked(false);
    setIsMatchDataClicked(false)
    setIsRaceWeekendClicked(false)
    setIsSuggestions(false)
    setIsMenuClicked(false)
    setIsChatClicked(false)
    history.push('/racing-space/settings');
    if (isMobile) {
      setIsMobileSidebarVisible(false); // Close sidebar on mobile
    }


  };
  const handleMenuClick = (key) => {

    console.log(`Clicked on item with key ${key}`);
    setIsDriverDataClicked(false);
    setIsDriverFormClicked(false);
    setIsAllDataClickedTelemetry(false)
    setIsCompetitionFormClicked(false);
    setIsManagerFormClicked(false);
    setIsAllDataClicked(false);
    setIsMatchDataClicked(false)
    setIsRaceWeekendClicked(false)
    setIsSuggestions(false)
    setIsMenuClicked(true)
    setIsSettingsClicked(false)

    if (isMobile) {
      setIsMobileSidebarVisible(false); // Close sidebar on mobile
    }


  };
  const handleChatClicked = (key) => {

    console.log(`Clicked on item with key ${key}`);
    setIsDriverDataClicked(false);
    setIsDriverFormClicked(false);
    setIsCompetitionFormClicked(false);
    setIsManagerFormClicked(false);
    setIsAllDataClickedTelemetry(false)
    setIsAllDataClicked(false);
    setIsMatchDataClicked(false)
    setIsRaceWeekendClicked(false)
    setIsSuggestions(false)
    setIsMenuClicked(false)
    setIsChatClicked(true)
    setIsSettingsClicked(false)

    history.push('/racing-space/chatbot');
    if (isMobile) {
      setIsMobileSidebarVisible(false); // Close sidebar on mobile
    }

  };





  const handleRacingGraphs = (key) => {

    console.log(`Clicked on item with key ${key}`);
    setIsDriverDataClicked(false);
    setIsDriverFormClicked(false);
    setIsCompetitionFormClicked(false);
    setIsManagerFormClicked(false);
    setIsAllDataClicked(false);
    setIsAllDataClickedTelemetry(false)
    setIsMatchDataClicked(false)
    setIsRaceWeekendClicked(true)
    setIsSuggestions(false)
    setIsMenuClicked(false)
    setIsChatClicked(false)
    setIsSettingsClicked(false)
    history.push('/racing-space/setup-analytics');
    if (isMobile) {
      setIsMobileSidebarVisible(false); // Close sidebar on mobile
    }



  };

  const handleDriverAnalytics = (key) => {

    console.log(`Clicked on item with key ${key}`);
    history.push('/racing-space/driver-analytics');
    if (isMobile) {
      setIsMobileSidebarVisible(false); // Close sidebar on mobile
    }



  };


  const handleRaceWeekendClick = (key) => {

    console.log(`Clicked on item with key ${key}`);
    setIsDriverDataClicked(false);
    setIsDriverFormClicked(false);
    setIsCompetitionFormClicked(false);
    setIsManagerFormClicked(true);
    setIsAllDataClickedTelemetry(false)
    setIsAllDataClicked(false);
    setIsMatchDataClicked(false)
    setIsRaceWeekendClicked(false)
    setIsSuggestions(false)
    setIsMenuClicked(false)
    setIsChatClicked(false)
    setIsSettingsClicked(false)


  };
  const handleMatchDataClick = (key) => {

    console.log(`Clicked on item with key ${key}`);
    setIsDriverDataClicked(false);
    setIsDriverFormClicked(false);
    setIsCompetitionFormClicked(false);
    setIsAllDataClickedTelemetry(false)
    setIsManagerFormClicked(false);
    setIsAllDataClicked(false);
    setIsMatchDataClicked(true)
    setIsRaceWeekendClicked(false)
    setIsSuggestions(false)
    setIsMenuClicked(false)
    setIsChatClicked(false)
    setIsSettingsClicked(false)


  };
  const handleDriverClick = (key) => {

    console.log(`Clicked on item with key ${key}`);
    setIsDriverFormClicked(true);
    setIsCompetitionFormClicked(false)
    setIsAllDataClickedTelemetry(false);
    setIsManagerFormClicked(false);
    setIsAllDataClicked(false);
    setIsDriverDataClicked(false);
    setIsMatchDataClicked(false)
    setIsRaceWeekendClicked(false)
    setIsSuggestions(false)
    setIsMenuClicked(false)
    setIsChatClicked(false)
    setIsSettingsClicked(false)

    history.push('/racing-space/racing-data');
    if (isMobile) {
      setIsMobileSidebarVisible(false); // Close sidebar on mobile
    }


  };
  const handleAllDataClick = (key) => {

    console.log(`Clicked on item with key ${key}`);
    setIsDriverFormClicked(false);
    setIsCompetitionFormClicked(false);
    setIsManagerFormClicked(false);
    setIsAllDataClicked(true)
    setIsAllDataClickedTelemetry(false)
    setIsDriverDataClicked(false);
    setIsMatchDataClicked(false)
    setIsRaceWeekendClicked(false)
    setIsSuggestions(false)
    setIsMenuClicked(false)
    setIsChatClicked(false)
    setIsSettingsClicked(false)

    history.push('/racing-space/archive');
    if (isMobile) {
      setIsMobileSidebarVisible(false); // Close sidebar on mobile
    }


  };

  const handleAllDataClickTelemetry = (key) => {

    console.log(`Clicked on item with key ${key}`);
    setIsDriverFormClicked(false);
    setIsCompetitionFormClicked(false);
    setIsManagerFormClicked(false);
    setIsAllDataClicked(false)
    setIsAllDataClickedTelemetry(true)
    setIsDriverDataClicked(false);
    setIsMatchDataClicked(false)
    setIsRaceWeekendClicked(false)
    setIsSuggestions(false)
    setIsMenuClicked(false)
    setIsChatClicked(false)
    setIsSettingsClicked(false)

    history.push('/racing-space/files-recap');
    if (isMobile) {
      setIsMobileSidebarVisible(false); // Close sidebar on mobile
    }


  };
  const chatClick = (key) => {

    console.log(`Clicked on item with key ${key}`);
    setIsDriverFormClicked(false);
    setIsCompetitionFormClicked(false);
    setIsManagerFormClicked(false);
    setIsAllDataClicked(true)
    setIsDriverDataClicked(false);
    setIsAllDataClickedTelemetry(false)
    setIsMatchDataClicked(false)
    setIsRaceWeekendClicked(false)
    setIsSuggestions(false)
    setIsMenuClicked(false)
    setIsChatClicked(false)
    setIsSettingsClicked(false)


  };
  const handleManagerClick = (key) => {

    console.log(`Clicked on item with key ${key}`);
    setIsDriverFormClicked(false);
    setIsCompetitionFormClicked(false);
    setIsManagerFormClicked(true);
    setIsAllDataClicked(false);
    setIsDriverDataClicked(false);
    setIsAllDataClickedTelemetry(false)
    setIsMatchDataClicked(false)
    setIsRaceWeekendClicked(false)
    setIsSuggestions(false)
    setIsMenuClicked(false)
    setIsChatClicked(false)
    setIsSettingsClicked(false)

    history.push('/racing-space/insert-manager');
    if (isMobile) {
      setIsMobileSidebarVisible(false); // Close sidebar on mobile
    }


  };

  const handleTelemetryClick = (key) => {

    console.log(`Clicked on item with key ${key}`);
    setIsDriverFormClicked(false);
    setIsCompetitionFormClicked(false);
    setIsManagerFormClicked(false);
    setIsAllDataClickedTelemetry(false)
    setIsAllDataClicked(false);
    setIsDriverDataClicked(false);
    setIsMatchDataClicked(false)
    setIsRaceWeekendClicked(false)
    setIsSuggestions(false)
    setIsMenuClicked(false)
    setIsChatClicked(false)
    setIsSettingsClicked(false)


    history.push('/racing-space/telemetry-data');
    if (isMobile) {
      setIsMobileSidebarVisible(false); // Close sidebar on mobile
    }


  };
  const handleCompetitionClick = (key) => {

    console.log(`Clicked on item with key ${key}`);
    setIsCompetitionFormClicked(true);
    setIsDriverFormClicked(false);
    setIsManagerFormClicked(false);
    setIsAllDataClicked(false);
    setIsAllDataClickedTelemetry(false)
    setIsDriverDataClicked(false);
    setIsMatchDataClicked(false)
    setIsRaceWeekendClicked(false)
    setIsSuggestions(false)
    setIsMenuClicked(false)
    setIsChatClicked(false)
    setIsSettingsClicked(false)


    history.push('/racing-space/insert-competition');
    if (isMobile) {
      setIsMobileSidebarVisible(false); // Close sidebar on mobile
    }


  };

  const [collapsed, setCollapsed] = useState(false);
  let analyticsItem = null;

  const [race, setRace] = useState("");
  const [day, setDay] = useState("");
  let logo = null;
  if (!collapsed) {
    logo = getItem(<div className="side-bar-logo" />, '1', null, null, handleMenuClick, '/racing-space');
  }
  const handlePricingClose = () => {
    setOpenPrice(!openPrice);
  };

  const handleLogoClick = () => {
    setIsDriverDataClicked(false);
    setIsDriverFormClicked(false);
    setIsCompetitionFormClicked(false);
    setIsManagerFormClicked(false);
    setIsAllDataClicked(false);
    setIsMatchDataClicked(false)
    setIsRaceWeekendClicked(false)
    setIsSuggestions(false)
    setIsMenuClicked(true)
    setIsSettingsClicked(false)
    history.push('/racing-space')
    if (isMobile) {
      setIsMobileSidebarVisible(false); // Close sidebar on mobile
    }
  }

  const handleTeamClick = (team) => {
    // Pick only the relevant properties to avoid circular references
    setShowTeams(!showTeams);

    if (selectedTeam.id !== team.id) {
      localStorage.setItem("selectedTeam", JSON.stringify(team));
      myUserStore.setSelectedTeam(team);
    }

  };

  const handleMyTeam = () => {
    //{ !showPersonalButton ? history.push(`/racing-space/settings/team/${selectedTeam.id}`) : history.push(`/racing-space/settings/`) }
    setShowTeams(!showTeams);
  }
  const items1 = myTeams
    .filter((team) => team.name !== selectedTeam.name) // Filter out the selected team
    .map((team, index) => {
      // Check if team name starts with "personal-"
      const displayName = team.name.startsWith("personal-") ? "Personal Space" : team.name;

      return getItem(displayName, 20 + index, null, null, () => handleTeamClick(team), null);
    });
  const items = isMobile
    ? [
      getItem('Generative AI', 'sub5', <SmartToyIcon />, [
        getItem('Chatbot', '16', null, null, handleChatClicked, '/racing-space/chat-bot'),
      ]),
      getItem('New Data', 'sub1', <SportsMotorsportsIcon />, [
        getItem('New Competion', '4', null, null, handleCompetitionClick, '/racing-space/insert-competition'),
        getItem(
          'Setup Data',
          '3',
          null,
          null,
          handleDriverClick,
          '/racing-space/racing-data'
        ),
        // getItem(
        //   'Track and Weather Conditions',
        //   '5',
        //   null,
        //   null,
        //   handleManagerClick,
        //   '/racing-space/insert-manager'
        // ),
        getItem(
          'Telemetry Data',
          '20',
          null,
          null,
          handleTelemetryClick,
          '/racing-space/telemetry-data'
        ),
      ]),
      getItem('Data Archive', 'sub2', <ArchiveIcon />, [
        getItem('Setup Archive', '6', null, null, handleAllDataClick, '/racing-space/archive'),
        getItem('Telemetry Archive', '7', null, null, handleAllDataClickTelemetry, '/racing-space/files-recap'),
      ]),
      getItem('Analytics', 'sub3', <BarChartIcon />, [
        getItem('Setup Analysis', '10', null, null, handleRacingGraphs, '/racing-space/setup-analytics'),
        getItem('Driver Analysis', '17', null, null, handleDriverAnalytics, '/racing-space/setup-analytics'),

      ]),
    ]
    : [
      getItem('Generative AI', 'sub5', <SmartToyIcon />, [
        getItem('Chatbot', '16', null, null, handleChatClicked, '/racing-space/chat-bot'),
      ]),
      getItem('New Data', 'sub1', <SportsMotorsportsIcon />, [
        getItem('New Competion', '4', null, null, handleCompetitionClick, '/racing-space/insert-competition'),
        getItem(
          'Setup Data',
          '3',
          null,
          null,
          handleDriverClick,
          '/racing-space/racing-data'
        ),
        // getItem(
        //   'Track and Weather ',
        //   '5',
        //   null,
        //   null,
        //   handleManagerClick,
        //   '/racing-space/insert-manager'
        // ),
        getItem(
          'Telemetry Data',
          '20',
          null,
          null,
          handleTelemetryClick,
          '/racing-space/telemetry-data'
        ),
      ]),
      getItem('Data Archive', 'sub2', <ArchiveIcon />, [
        getItem('Setup Archive', '6', null, null, handleAllDataClick, '/racing-space/archive'),
        getItem('Telemetry Archive', '7', null, null, handleAllDataClickTelemetry, '/racing-space/files-recap'),
      ]),
      getItem('Analytics', 'sub3', <BarChartIcon />, [
        getItem('Setup Analysis', '10', null, null, handleRacingGraphs, '/racing-space/setup-analytics'),
        getItem('Driver Analysis', '17', null, null, handleDriverAnalytics, '/racing-space/setup-analytics'),
      ]),
    ].filter(Boolean);

  const [IsChatClicked, setIsChatClicked] = useState(false);
  const [IsDriverFormClicked, setIsDriverFormClicked] = useState(false);
  const [IsCompetitionFormClicked, setIsCompetitionFormClicked] = useState(false)
  const [IsManagerFormClicked, setIsManagerFormClicked] = useState(false)
  const [IsAllDataClicked, setIsAllDataClicked] = useState(false)
  const [IsAllDataClickedTelemetry, setIsAllDataClickedTelemetry] = useState(false)
  const [IsDriverDataClicked, setIsDriverDataClicked] = useState(false)
  const [IsMatchDataClicked, setIsMatchDataClicked] = useState(false)
  const [IsRaceWeekendClicked, setIsRaceWeekendClicked] = useState(false)
  const [IsSuggestions, setIsSuggestions] = useState(false)
  const [IsMenuClicked, setIsMenuClicked] = useState(true)
  const [IsSettingsClicked, setIsSettingsClicked] = useState(true)

  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();



  useEffect(() => {
    console.log("...")
    if (!subDetails) {
      console.log("getting  subdtails")
      getSubscription();
    }
  }, [, IsSettingsClicked]);



  const [racingData, setRacingData] = useState([])

  const [category, setCategory] = useState('');
  const [competitions, setCompetitions] = useState([]);

  const [showFront, setShowFront] = useState(false);

  const [showPressure, setShowPressure] = useState(false);
  const [drivers, setDrivers] = useState([]);
  const [driver, setDriver] = useState([]);
  const [showEngine, setShowEngine] = useState(true);

  const [value, setValue] = React.useState(0);
  const [anchorElTeamAnalysis, setAnchorElTeamAnalysis] = useState(null);
  const [selectedOptionTeamAnalysis, setSelectedOptionTeamAnalysis] = useState('');
  const [showComponent, setShowComponent] = useState(false);
  const [selectedDay, setSelectedDay] = useState(false);
  const [isMobileSidebarVisible, setIsMobileSidebarVisible] = useState(false);
  const sidebarRef = useRef(null);
  const buttonRef = useRef(null);

  const toggleMobileSidebar = () => {
    if (!sidebarRef.current) {
      setIsMobileSidebarVisible(false);
    } else {
      setIsMobileSidebarVisible(true)
    }



  };
  const handleClickOutside = (event) => {
    if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
      setIsMobileSidebarVisible(false);
    }
  };
  const handleClickMenu = (event) => {
    if (buttonRef.current && buttonRef.current.contains(event.target)) {
      setIsMobileSidebarVisible(true);
    }
  };

  useEffect(() => {
    if (isMobileSidebarVisible) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    if (!isMobileSidebarVisible) {
      document.addEventListener('mousedown', handleClickMenu);
    } else {
      document.removeEventListener('mousedown', handleClickMenu);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isMobileSidebarVisible]);

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
    setShowEngine(newValue === 0)
    setShowFront(newValue === 1)
    setShowPressure(newValue === 2)
  };







  useEffect(() => {
    console.log("Category:", category);
    console.log("Race:", race);
    console.log("Day:", day);
    axios
      .get(`${process.env.REACT_APP_API_DOMAIN}/get_average_laptime_by_category/?category=${category}&race=${race}&day=${day}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          accept: "application/json",
        },
      })
      .then((res) => {
        console.log("AVG API Response:", res.data);
        setRacingData(res.data);
      })

      .catch((error) => {
        console.error("Axios Error:", error);
      });
  }, [category, race, day,]);


  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_DOMAIN}/get_drivers/?team_id=${3}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          accept: "application/json",
        },
      })
      .then((res) => {
        console.log("Drivers API Response:", res.data);
        setDrivers(res.data);
      })
      .catch((error) => {
        console.error("Axios Error:", error);
      });
  }, [, selectedTeam]);











  const handleChange = (event) => {
    // If multiple options can be selected, event.target.value will be an array
    const selectedDrivers = event.target.value;
    setDriver(selectedDrivers);
  };



  // Use showEngine for engine graphs
  ;

  const [anchorEl, setAnchorEl] = useState(null);
  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
    setSelectedDay(true);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const handleSelectDay = (day) => {
    console.log('Selected day:', day);
    setDay(day);
    handleMenuClose();
  };

  const extractModifiedLaptime = (racingData) => {
    const modifiedRacingData = racingData.map((item) => {
      const laptimeString = item.avg_laptime;
      if (typeof laptimeString !== 'string') {
        console.error("laptimeString is not a string:", laptimeString);
        return null; // o qualsiasi altro valore significativo
      }
      const [hours, minutes, seconds] = laptimeString.split(":");
      const totalSeconds = parseInt(hours) * 60 + parseInt(minutes) + 0.01 * parseInt(seconds);
      const laptimeCalc = totalSeconds;

      // Restituisci un nuovo oggetto con laptimeCalc aggiunto
      return {
        ...item,
        laptimeCalc
      };
    }).filter(item => item !== null); // Rimuovi i valori nulli, se presenti

    return modifiedRacingData;
  };

  const newDataset4 = [
    { category: "Senior", sessionNumber: 1, race: "Lonato", avglaptime: 55.4, day: 1 },
    { category: "Senior", sessionNumber: 2, race: "Lonato", avglaptime: 53.4, day: 1 },
    { category: "Senior", sessionNumber: 3, race: "Lonato", avglaptime: 57.4, day: 1 },
    { category: "Senior", sessionNumber: 4, race: "Lonato", avglaptime: 58.4, day: 1 },
    { category: "Senior", sessionNumber: 5, race: "Lonato", avglaptime: 58.4, day: 1 },
    { category: "Senior", sessionNumber: 6, race: "Lonato", avglaptime: 52.4, day: 1 },
  ];

  // const sessionNumbers = Array.isArray(racingData) ? racingData.map(data => data.session_number) : [];
  // const avgLapTimes = Array.isArray(racingData) ? extractModifiedLaptime(racingData).map(data => data.avg_laptime) : [];
  const modifiedRacingData = extractModifiedLaptime(racingData);
  console.log("Modified Racing Data:", modifiedRacingData);
  const sessionNumbers = racingData.map(data => data.session_number);
  const avgLapTimes = modifiedRacingData.map(data => data.laptimeCalc);
  const myPlan = myUserStore.planCode
  const status = myUserStore.status
  let planLabel
  if (myPlan != "Pro" || myPlan != "Team" || status == 'CANCELLED') {
    planLabel = "Upgrade to Pro"
  }
  if (myPlan == "Pro") {
    planLabel = "Upgrade to Team"
  }
  if (myPlan == "Team" && status != 'CANCELLED') {
    planLabel = false
  }
  function getItem(label, key, icon, children, onClick, href) {

    return {
      key,
      icon,
      children,
      //((race && day && ((href == "/racing-space/archive") || (href == "/racing-space/analytics"))) || (race && ((href != "/racing-space/archive") && (href != "/racing-space/analytics"))) || (href == "/racing-space/insert-competition") || (href == "/racing-space") || (href == "/racing-space/settings") || (href == '/racing-space/chatbot')) &&*/},
      label: href ? <NavLink className="no-underline" to={href}>{label}</NavLink> : label,
      onClick,

    };
  }

  function getItemNoClick(label, key, icon, children, href) {

    return {
      key,
      icon,
      children,
      //((race && day && ((href == "/racing-space/archive") || (href == "/racing-space/analytics"))) || (race && ((href != "/racing-space/archive") && (href != "/racing-space/analytics"))) || (href == "/racing-space/insert-competition") || (href == "/racing-space") || (href == "/racing-space/settings") || (href == '/racing-space/chatbot')) &&*/},
      label: href ? <NavLink className="no-underline" to={href}>{label}</NavLink> : label,

    };
  }

  const transformData = (data) => {
    const { email, surname, teams, owner, teams_info } = data;

    const transformedTeamsInfo = teams_info.map(team => ({
      id: team.team_id,
      name: team.team_name,
      role: team.role,
      isOwner: owner.some(t => t.team_id === team.team_id && t.team_owner === parsedUserData.user_id),
      members: team.members
    }));

    return {
      email: parsedUserData.email,
      surname: parsedUserData.surname,
      teams: teams.map(team => team.team_name),
      owners: owner,
      teamInfo: transformedTeamsInfo
    };
  };
  const currentDate = new Date();
  const currentTimestamp = currentDate.getTime();
  const currentDate1 = dayjs().format("YYYY-MM-DD");
  console.log('current Date', currentDate1)

  const getSettings = async (dont) => {
    try {
      setIsLoading(true);
      const res = await axios.get(
        `${process.env.REACT_APP_API_DOMAIN}/get_settings`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            accept: "application/json",
          },
        }
      );

      console.log("Team info before", res.data);
      setMyTeams(transformData(res.data)?.teamInfo);
      console.log("My Teams", transformData(res.data)?.teams);
      setIsLoading(false);

    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching data:", error);
      return false;
    }
  };
  useEffect(() => {

    getSettings();
  }, [showTeams]);


  return (
    <Layout
      style={{
        height: '100%',
      }}

    >
      <Sider

        collapsible={!isMobile}
        collapsed={isMobile ? false : collapsed}
        onCollapse={(value) => setCollapsed(value)}
        className={`mobile-sidebar ${isMobileSidebarVisible ? 'visible' : ''}`}
        ref={sidebarRef} anchor="left"
      >
        {collapsed && (<div className="demo-logo-vertical" />)}
        {((!collapsed) || (isMobile)) && (
          <div className="side-bar-logo" onClick={handleLogoClick} style={{ cursor: 'pointer', zIndex: 100, marginLeft: 50, marginBlock: 20, marginTop: 15 }} />
        )}
        {((!collapsed) || (isMobile)) && (
          <div
            style={{
              marginLeft: 30,
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center', // Align items vertically
              justifyContent: 'space-between',
              gap: 5, // Distribute space between elements
              position: 'relative', // Position relative to handle hover
            }}
            onClick={handleMyTeam}
            onMouseEnter={() => setShowHover(true)}
            onMouseLeave={() => setShowHover(false)}
          >
            <div style={{ display: 'flex', flexDirection: 'column' }}> {/* Stack texts in a column */}
              <div style={{ fontSize: 18, color: "#adb5bd", fontWeight: 700 }}>
                {!showPersonalButton ? selectedTeam.name : <Chip label="Personal Space" color="info" variant="outlined" />}
              </div>
              {!showPersonalButton && (
                <div style={{ padding: 0, fontSize: 12, color: "#adb5bd" }}>
                  {selectedTeam?.members?.length} members
                </div>
              )}
            </div>
            {/* Icons on the right, vertically centered */}
            {showTeams ? (
              <ExpandLessIcon sx={{ color: '#adb5bd', marginRight: 2 }} />
            ) : (
              <ChangeCircleIcon
                sx={{
                  color: '#adb5bd',
                  marginRight: 2,
                  opacity: showHover ? 1 : 0, // Control visibility with state
                  transition: 'opacity 0.3s ease', // Smooth transition effect
                }}
              />
            )}
          </div>
        )}
        {showTeams && (<Menu className='side-bar' theme="dark" defaultSelectedKeys={['1']} mode="inline" items={items1} />)}


        <Menu className='side-bar' theme="dark" defaultSelectedKeys={['1']} mode="inline" items={items} />
        <Stack spacing={3} mt={'auto'} py={4}>
          {!collapsed && planLabel && (
            <div className="settings-logo1">
              <div onClick={handlePricingClose} style={{ cursor: "pointer", width: 150, display: "flex", justifyContent: 'center' }} >
                <Chip
                  sx={{
                    width: "85%",
                    backgroundColor: '#DAA520',
                    color: 'black',
                    fontWeight: 'bold',
                    position: 'relative',
                    overflow: 'hidden',
                    fontSize: 12,

                    '&::before': {
                      content: '""',
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      background: 'linear-gradient(145deg, rgba(255,255,255,0.6), rgba(255,255,255,0))',
                      opacity: 0.5,
                      transition: 'opacity 0.3s',
                      pointerEvents: 'none',
                    },
                    '&:hover::before': {
                      opacity: 1,
                    },
                  }}
                  label={planLabel}
                />

              </div>
            </div>
          )}

          <div className="settings-logo" style={{ cursor: "pointer" }} onClick={handleSettingsClick} >
            {!collapsed && (<Typography className="hover-effect" sx={{ color: "white", fontSize: 18, alignItems: 'center', }}> Settings</Typography>)}
            <SettingsIcon className="hover-effect" sx={{ color: "white", marginLeft: collapsed ? 0 : 2 }} />
          </div>
        </Stack>
      </Sider>
      <Layout>
        <Box
          sx={{
            margin: { xs: 0, sm: '16px' },
            position: "relative",
            overflow: 'hidden',
            height: '100%'
          }}
        >
          {isMobile && (<Box display={"flex"} justifyContent={"end"} p="10px" >
            <IconButton className="mobile-sidebar-button" ref={buttonRef} >
              <MenuIcon />
            </IconButton>
          </Box>)
          }
          <Box
            sx={{
              padding: { xs: '24px 18px', md: '24px' },
              height: '100%',
              overflow: 'auto',
              border: `2px solid ${colorBgContainer}`,
              background: colorBgContainer,
              borderRadius: { sm: borderRadiusLG },
            }}

          >
            <Box className="draggable-parent">
              <>
                <Switch>
                  <Route path={"/racing-space/chat-bot"}>
                    <ChatBot />
                  </Route>
                  <Route path={"/racing-space/files-recap"}>
                    <FilesRecap />
                  </Route>

                  <Route path={"/racing-space/archive"}>
                    <DataArchive />
                  </Route>
                  <Route path={"/racing-space/upload-telemetry"}>
                    <UploadTelemetry />
                  </Route>
                  <Route path={"/racing-space/insert-driver"}>
                    <InsertDriver race={race} />
                  </Route>
                  <Route path={"/racing-space/insert-competition"}>
                    <CompetitionForm />
                  </Route>
                  <Route path={"/racing-space/chatbot"}>
                    <ChatBot />
                  </Route>
                  <Route path={"/racing-space/insert-manager"}>
                    <ManagerForm />
                  </Route>
                  <Route path={"/racing-space/setup-analytics"}>
                    <ParentRaceManager />
                  </Route>
                  <Route path={`/racing-space/settings/team/:teamId`}>
                    <Settings1 />
                  </Route>
                  <Route path={"/racing-space/settings"}>
                    <Settings isLoggedIn={isLoggedIn} />
                  </Route>
                  <Route path={"/racing-space/racing-data"}>
                    <Racingdata />
                  </Route>

                  <Route path={"/racing-space/plans"}>
                    <UserPlan />
                  </Route>

                  <Route path={"/racing-space/driver-analytics"}>
                    <DriverAnalytics collapsed={isMobile ? true : collapsed} />
                  </Route>
                  <Route path={"/racing-space/telemetry-data"}>
                    <Telemetrydata />
                  </Route>
                  <Route path={"/racing-space/graph-telemetry"}>
                    <GraphTelemetry />
                  </Route>
                  <Route path={"/racing-space/telemetry-parent"}>
                    <TelemetryParent />
                  </Route>
                  <Route path={"/racing-space/telemetry-table"}>
                    <Telemetrytable />
                  </Route>
                  <Route path={"/racing-space/track-map"}>
                    <TrackMap />
                  </Route>


                  <Route path={"/racing-space"}>
                    {IsMenuClicked && (
                      <div style={{ overflow: 'auto' }}>
                        <div className="container19">
           
                          <div>
                            {/* Empty div - add content or remove */}
                          </div>
                        </div>


                        <ChatBot />
                        {/* <HeroSectionExchangeData /> */}
                      </div>
                    )}
                  </Route>
                </Switch>
              </>
            </Box>
          </Box>
        </Box>
      </Layout>
      <Pricing open={openPrice} onClose={handlePricingClose} />
    </Layout>

  );
};
export default observer(RacingSpace);