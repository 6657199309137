import "react-toastify/dist/ReactToastify.css";

import {
	Button,
	Col,
	Container,
	Form,
	Modal,
	Row,
	Stack,
} from "react-bootstrap";
import {
	FormControl,
	FormGroup,
	Grid,
	InputLabel,
	MenuItem,
	Select,
	TextField,
	responsiveFontSizes
} from '@mui/material';
import React, { useEffect, useState } from "react";

import Buttonn from '@mui/material/Button';
import OutlinedInput from '@mui/material/OutlinedInput';
import TrySubscribe from "../Try_Subscribe"; // Updated to use SubscribeDialog component
import Typography from '@mui/material/Typography';
import axios from "axios";
import myUserStore from "../../../stores/UserStore";
import { observer } from "mobx-react-lite"
import { set } from "date-fns";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

const CompetitionForm = () => {
	const history = useHistory();
	const [locations, setLocations] = useState([]);
	const [track, setTrack] = useState("Lonato");
	const [competition, setCompetition] = useState("");
	const [newTrack, setNewTrack] = useState("");
	const [newTimezone, setNewTimezone] = useState("");
	const [loading, setLoading] = useState(false);
	const [showTrySubscribe, setShowTrySubscribe] = useState(false); // State to control the display of Try_Subscribe
	const [dialogOpen, setDialogOpen] = useState(false); // State to control dialog visibility
	//const selectedTeam = JSON.parse(localStorage.getItem("selectedTeam"));
	const selectedTeam = myUserStore.getSelectedTeam;
	
	const addLocation = () => {
		const apiUrl = `${process.env.REACT_APP_API_DOMAIN}/insert_location_timezone/?location_name=${newTrack}&timezone=${newTimezone}`;
		axios
			.post(
				apiUrl,
				{},
				{
					headers: {
						Authorization: `Bearer ${localStorage.getItem("access_token")}`,
						accept: "application/json",
					},
				}
			)
			.then((response) => {
				const data = response.data;
				setTrack(newTrack);
				if (response.status === 200) {
					setNewTrack("");
				}
				toast.success("Competition inserted correctly!", {
					position: "top-right",
					autoClose: 2000,
					hideProgressBar: false,
				});
				handleClose();
			})
			.catch((error) => {
				toast.error("Competition not inserted correctly!", {
					position: "top-right",
					autoClose: 2000,
					hideProgressBar: false,
				});
				console.error("Query Error:", error);
			});
	};

	const [show, setShow] = useState(false);

	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);
	
	useEffect(() => {
		axios
			.get(`${process.env.REACT_APP_API_DOMAIN}/get_locations/`)
			.then((res) => {
				const locations = res.data;
				setLocations(locations);
			})
			.catch((error) => {
				console.error("Axios Error:", error);
			});
	}, []);

	// Function to handle form submission
	const performQuery = (e) => {
		e.preventDefault();
		setDialogOpen(true); // Open the dialog directly when the button is clicked
	};

	return (
		<Container style={{ height: '85vh' }}>
			<Modal show={show} onHide={handleClose} style={{ top: "30vh" }}>
				<Modal.Header closeButton>
					<Modal.Title>Insert new Track</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Row>
						<Col xs={12} sm={8} md={8}>
							Track Name
							<Form.Control
								type="text"
								id="track"
								value={newTrack}
								required
								onChange={(e) => {
									setNewTrack(e.target.value);
								}}
							/>
						</Col>
						<Col xs={12} sm={4} md={4}>
							Timezone
							<Form.Control
								type="number"
								id="timezone"
								value={newTimezone}
								required
								onChange={(e) => {
									setNewTimezone(e.target.value);
								}}
							/>
						</Col>
					</Row>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleClose}>
						Close
					</Button>
					<Button variant="secondary " onClick={performQuery}>
						Add
					</Button>
				</Modal.Footer>
			</Modal>
			<Form
				onSubmit={(e) => {
					e.preventDefault();
					performQuery(e); // Call performQuery on form submit
				}}
			>
				<Row>
					<Col xs={12} sm={6} md={4}>
						<Typography > Competition Name:</Typography>
						<TextField
							onChange={(e) => setCompetition(e.target.value)}
							value={competition}
							placeholder="Label this session"
							multiline
							fullWidth
							sx={{ marginTop: 1, marginBottom: 3 }}
						/>
					</Col>
					<Col xs={12} sm={6} md={4}>
						<Form.Group className="mb-3" >
							<Typography > Select track:</Typography>
							<div style={{ display: "flex", flexDirection: 'row' }}>
								<FormControl sx={{ marginTop: 1, marginBottom: 3, }} style={{ width: '100%' }}>
									<Select
										labelId="demo-multiple-chip-label"
										id="demo-multiple-chip"
										value={track}
										placeholder="Select track"
										onChange={(e) => setTrack(e.target.value)}
									>
										{locations.map((location) => (
											<MenuItem
												key={location["location"]}
												value={location["location"]}
											>
												{location["location"]}
											</MenuItem>
										))}
									</Select>
								</FormControl>
								<Button variant="light" style={{ width: 50, height: 50, marginTop: 10, marginLeft: 10 }} onClick={handleShow}>
									+
								</Button>
							</div>
						</Form.Group>

					</Col>
				</Row>
				<Row>
					<Col xs={12} sm={6} md={4}>
						<Form.Group className="mb-3" controlId="kartSlipSelect">
							<Buttonn variant='contained' disabled={loading} onClick={performQuery}>
								{loading ? "Submitting..." : "Submit"}
							</Buttonn>
						</Form.Group>
					</Col>
				</Row>
				<div style={{ height: 400 }}>
				</div>

			</Form>

			{/* Render the SubscribeDialog component */}
			<TrySubscribe open={dialogOpen} onClose={() => setDialogOpen(false)} />
		</Container>
	);
};

export default observer(CompetitionForm);
