import { CircularProgress, Stack } from '@mui/material';
import { PayPalButtons, usePayPalScriptReducer } from "@paypal/react-paypal-js";
import React, { useEffect, useState } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from "@mui/material";
import PaypalProvider from "services/PaypalProvider";
import axios from "axios";
import myUserStore from "stores/UserStore";
import { preparePlanOptions } from "services/PaypalHelper";
import { sub } from "date-fns";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from 'uuid';

// flusso paypal utenti registrati
// alla success abilitare l'utente (cambio flag disabled false -> true)
// se invece l'utente è disablilitato rimandarlo alla pagina dei pricing per la scelta del piano
// in un secondo momento capire come creare una convalida della transazione per evitare frodi

const PayPalButton = ({
    period,
    price,
    currentSubscriptionId,
    selectedPlan = {},
    onUndoPayment = () => { },
    onPaymentComplete = () => { },
    isTrial
}) => {

    const CLIENT_ID = process.env.REACT_APP_PAYPAL_CLIENT_ID;
    const SECRET = process.env.REACT_APP_PAYPAL_SECRET;
    console.log ("period", period)
    console.log ("Need a trial?", isTrial)
    const [{ options, isPending }, dispatch] = usePayPalScriptReducer();
    const [currency, setCurrency] = useState(options.currency);
    const [planId, setPlanId] = useState(''); // State to store plan ID
    const [isLoading, setIsLoading] = useState(true);
    const [subscriptionId, setSubscriptionId] = useState('');

    const getToken = async () => {
        setIsLoading(true);
        try {
            const response = await PaypalProvider.post('oauth2/token',
                'grant_type=client_credentials',
                {
                    auth: {
                        username: CLIENT_ID,
                        password: SECRET
                    },
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    }
                }
            );
            console.log('Token:', response.data.access_token);
            return response.data.access_token;
        } catch (error) {
            console.error('Error getting token:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const createProduct = async (token) => {
        try {
            const response = await PaypalProvider.post('catalogs/products', {
                name: "AiMotor subscription plan",
                description: "Subscription to Aimotor services",
                type: "SERVICE",
                category: "SOFTWARE",
                image_url: "https://example.com/streaming.jpg",
                home_url: "https://aimotor.eu/"
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                    'PayPal-Request-Id': uuidv4() // Generate a unique ID for each request
                }
            });
            console.log('Product ID:', response.data.id);
            return response.data.id;
        } catch (error) {
            console.error('Error creating product:', error);
        }
    };

    const createPlan = async (token, productId) => {
        try {
            const response = await PaypalProvider.post('billing/plans', {
                product_id: productId,
                name: selectedPlan?.title || "Basic plan",
                description: selectedPlan?.title || "Basic plan",
                billing_cycles: preparePlanOptions(selectedPlan.title, { period, price, currency, isTrial }),
                payment_preferences: {
                    auto_bill_outstanding: true,
                    setup_fee_failure_action: "CONTINUE",
                    payment_failure_threshold: 3
                },
                taxes: {
                    percentage: "0",
                    inclusive: false
                }
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                    'PayPal-Request-Id': uuidv4() // Generate a unique ID for each request
                }
            });
            console.log('Plan ID:', response.data.id);
            return response.data.id;
        } catch (error) {
            console.error('Error creating plan:', error);
        }
    };

    const getSubscriptionDetails = async (subscriptionId) => {
        const token = await getToken();
        if (token) {
            try {
                const response = await axios.get(`https://api-m.sandbox.paypal.com/v1/billing/subscriptions/${subscriptionId}`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    }
                });
                console.log('Subscription Details:', response.data);
                return response.data;
            } catch (error) {
                console.error('Error getting subscription details:', error);
            }
        }
    };


    const postSubscription = async (subscriptionDetails) => {

        console.log("details recieved", subscriptionDetails)
        const user = JSON.parse(localStorage.getItem("user_data") || {})
        if (user && user.email) {
            const apiUrl = `${process.env.REACT_APP_API_DOMAIN}/post_subscription/`
            await axios.post(
                apiUrl,
                {
                    user_id: user.user_id,
                    user_email: user.email,
                    subscriptionDetails
                },
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                        accept: "application/json",
                    },
                }
            )
                .then(
                    (response) => {
                        toast.success("Plan Changed!", {
                            position: "top-right",
                            autoClose: 2000,
                            hideProgressBar: false,
                        });

                    }

                )
                .catch(
                    (error) => {
                        console.error("Error!", error)
                    }
                )
        } else {
            console.error('no email found')
            toast.error("Email not found!", {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
            });
        }

    };

    useEffect(() => {
        localStorage.setItem('subscriptionId', subscriptionId);
    }, [subscriptionId]);


    const updateSubscription = async (subscriptionId, updateData) => {
        const token = await getToken();
        if (token) {
            try {
                const response = await axios.patch(`https://api-m.sandbox.paypal.com/v1/billing/subscriptions/${subscriptionId}`, updateData, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    }
                });
                console.log('Updated Subscription:', response.data);
                return response.data;
            } catch (error) {
                console.error('Error updating subscription:', error);
            }
        }
    };

    const cancelSubscription = async (subscriptionId) => {
        const token = await getToken();
        console.log("sub id while canceling", subscriptionId)
        if (token) {
            try {
                const response = await PaypalProvider.post(`billing/subscriptions/${subscriptionId}/cancel`, {
                    reason: 'User requested cancellation'
                }, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    }
                });
                console.log('Cancelled Subscription:', response.data);
                return response.data;
            } catch (error) {
                console.error('Error cancelling subscription:', error);
            }
        }
    };

    const setupPayPal = async () => {
        setIsLoading(true);
        const token = await getToken();
        if (token) {
            const productId = await createProduct(token);
            if (productId) {
                const newPlanId = await createPlan(token, productId);
                console.log('New Plan ID:', newPlanId);
                setPlanId(newPlanId);
                myUserStore.setUserPlanId(newPlanId)
                if (newPlanId) {
                    setIsLoading(false);
                }
            }
        }
    };

    {/*useEffect(() => {
        setupPayPal();
    }, []);*/}

    const onCurrencyChange = ({ target: { value } }) => {
        setCurrency(value);
        dispatch({
            type: "resetOptions",
            value: {
                ...options,
                currency: value,
            },
        });
    };

    const createNewSubscription = (data, actions) => {
        return actions.subscription.create({
            plan_id: planId,
            custom_id: selectedPlan.title // the id of selected plan
        }).then((subscription) => {
            setSubscriptionId(subscription);
            return subscription
        }).catch(error => {
            if (error === 'popup_closed') {
                console.log('Popup was closed by the user.');
            } else {
                console.error('Subscription creation error:', error);
            }
        });

    };

    const onApproveSubscription = (data, actions) => {
        return actions.subscription.get().then((details) => {
            handlePaymentSuccess(details);
        }).catch(error => {
            console.error('Subscription approval error:', error);
        });
    };

    const handlePaymentSuccess = (subscriptionDetails) => {
        myUserStore.setUserSubscriptionDetails(subscriptionDetails)
        postSubscription(subscriptionDetails);
        setSubscriptionId(subscriptionDetails.id)
        onPaymentComplete(subscriptionDetails, selectedPlan)
        // remove old subscription if present
        // if currentSubscriptionId is empty paypal return error
        cancelSubscription(currentSubscriptionId);
        // Add your custom logic here
    };

    const resetPaypal = () => {
        setPlanId('')
        setSubscriptionId('')
    }

    useEffect(() => {
        setupPayPal();

        return () => {
            resetPaypal()
        }
    }, [selectedPlan?.title]);

    return (
        <div className="pay-pal">
           
            <IconButton sx={{
                position: 'absolute',
                right: 5,
                top: 5
            }} onClick={onUndoPayment}>
                <CloseIcon />
            </IconButton>
            <CircularProgress hidden={!isPending || !isLoading} />
            <div className="select-wrapper">
                <select className="custom-select" value={currency} onChange={onCurrencyChange}>
                    <option value="USD">💵 USD</option>
                    
                    <option value="EUR">💶 Euro</option>
                </select>
            </div>

            
            {planId ? (
                <div className='paypal-buttons'>
                    <PayPalButtons
                        style={{ layout: "vertical", display: "flex", justifyContent: "center", shape: "pill", disableMaxWidth: true }}
                        createSubscription={(data, actions) => createNewSubscription(data, actions)}
                        onApprove={(data, actions) => onApproveSubscription(data, actions)}
                    />
                </div>
            ) : (
                <Stack direction="row" spacing={3} alignItems={'center'} py={2} color={'black'}>
                    <CircularProgress />
                    <span>Loading PayPal...</span>
                </Stack>
            )}
            {/* <h1>Plain id: {planId}</h1>
            <h1>Subscription {subscriptionId}</h1>
            <h1>old sub {currentSubscriptionId}</h1> */}
        </div>
    );
};

export default PayPalButton;
