import React, { useState } from 'react';
import { observer } from "mobx-react-lite";
import PropTypes from 'prop-types';
import axios from 'axios';
 // Adjust the path as necessary

 import useMediaQuery from '@mui/material/useMediaQuery';


// MUI Components
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Menu,
  OutlinedInput,
  Popover,
  Skeleton,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';
import { alpha, styled, useTheme } from '@mui/material/styles';

import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import ArchiveIcon from '@mui/icons-material/Archive';
import Autocomplete from '@mui/material/Autocomplete';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import EditIcon from '@mui/icons-material/Edit';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { PieChart } from '@mui/x-charts/PieChart';

import Select, { SelectChangeEvent } from '@mui/material/Select';

// Custom Components
import CorniceFront from './CorniceFront';
import CornicePressure from './CornicePressure';
import FilesRecap from 'components/FilesRecap/Recap.js';
import InsertData from "./InsertData.js";
import InsertDriver from './InsertDriver.js';
import ManagerForm from "../ManagerForm.js";
import TelemetryDownload from './TelemetryDownload.js';
import UploadTelemetry from './UploadTelemetry.js';

// Store
import myUserStore from '../../stores/UserStore';

// Constants for Menu
const itemHeight = 48;
const itemPaddingTop = 8;
const menuProps = {
  PaperProps: {
    style: {
      maxHeight: itemHeight * 4.5 + itemPaddingTop,
      width: 250,
    },
  },
};

// Helper Function for Styles
function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

// Custom Tab Panel Component
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

// Accessibility Props for Tabs
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const PieChartWithSelect = () => {
  // State Variables
  const [refreshUploadList, setRefreshUploadList] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [value, setValue] = useState(0);
  const [anchorElTeamAnalysis, setAnchorElTeamAnalysis] = useState(null);
  const [selectedOptionTeamAnalysis, setSelectedOptionTeamAnalysis] = useState('');
  const [competitions, setCompetitions] = useState([]);
  const [racingData, setRacingData] = useState([]);

  const [showFront, setShowFront] = useState(false);
  const [showPressure, setShowPressure] = useState(false);
  const [drivers, setDrivers] = useState([]);
  const [emailName, setEmailName] = useState([]);
  const [driver, setDriver] = useState([]);
  const [showEngine, setShowEngine] = useState(true); // Use showEngine for engine graphs
  const [race, setRace] = useState("");
  const [day, setDay] = useState("");
  const [selectedDate, setSelectedDate] = useState(null);
  const [competitionLoading, setCompetitionLoading] = useState(false);

  // New State for Help Dialog and Image Display
  const [helpOpen, setHelpOpen] = useState(false);
  const [selectedHelpImage, setSelectedHelpImage] = useState(null); // null means no image selected

  // Theme
  const theme = useTheme();

  // Handlers for Tabs
  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
    setShowEngine(newValue === 0);
    setShowFront(newValue === 1);
    setShowPressure(newValue === 2);
  };
  const handleChangeTab1 = (newValue) => {
    setValue(1);
    setShowEngine(false);
    setShowFront(true);
  };

  // Handler for Date Change
  const handleDateChange = (date) => {
    setValue(1);
    setSelectedDate(date);
    handleMenuClose();
  };

  // Menu Handlers
  const open = Boolean(anchorEl);
  const id = open ? 'date-calendar-popover' : undefined;
  const selectedTeam = myUserStore.getSelectedTeam;
  const showPersonalButton = myUserStore.isPersonalSpace;
  console.log("selected team", selectedTeam.id);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  // Upload Completion Handler
  const handleUploadComplete = (status) => {
    setRefreshUploadList(status);
  };

  // Handlers for Help Dialog
  const handleHelpOpen = () => {
    setHelpOpen(true);
    setSelectedHelpImage(null); // Reset any selected image
  };
  const handleHelpClose = () => {
    setHelpOpen(false);
    setSelectedHelpImage(null); // Reset any selected image
  };

  // Handlers for Chip Clicks
  const handleChipClick = (mobileImageUrl, desktopImageUrl) => {
    setSelectedHelpImage(isMobile ? mobileImageUrl : desktopImageUrl);
  };
  // Handler to go back to Chip selection
  const handleBackToChips = () => {
    setSelectedHelpImage(null);
  };
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')); 

  return (
    <Box sx={{ position: 'relative', width: '100%', }}>
      {/* Header */}
      <Grid
        item
        lg={showPersonalButton ? 6 : 4}
        md={showPersonalButton ? 6 : 4}
        sm={showPersonalButton ? 6 : 4}
        xs={12}
        sx={{ marginBlock: 2 }}
      >
        <Typography sx={{ fontSize: 40 }}>Telemetry Data</Typography>
      </Grid>

      {/* Tabs and Content */}
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs variant="fullWidth" value={value} onChange={handleChangeTab}>
            <Tab label="DOWNLOAD DATA" {...a11yProps(0)} />
            <Tab label="IMPORT DATA" {...a11yProps(1)} />
          </Tabs>
        </Box>
        <div className='graphs'>
          {showEngine && (
            <TelemetryDownload
              race={race}
              day={day}
              driver={driver}
              fullName={emailName}
              showTable={!!race && !!day}
              handleChipClick={handleChangeTab1}
            />
          )}
          {showFront && (
            <UploadTelemetry
              race={race}
              day={day}
              driver={driver}
              fullName={emailName}
              showTable={!!race && !!day}
              onComplete={handleUploadComplete}
            />
          )}
        </div>
      </Box>

      {/* Floating QuestionMarkIcon */}
      <Box sx={{ position: 'fixed', bottom: 26, right: 26, zIndex: 1000 }}>
        <IconButton
          color="primary"
          aria-label="help"
          onClick={handleHelpOpen}
          sx={{
            backgroundColor: alpha(theme.palette.primary.main, 0.1),
            '&:hover': {
              backgroundColor: alpha(theme.palette.primary.main, 0.2),
            },
          }}
        >
          <QuestionMarkIcon />
        </IconButton>
      </Box>

      {/* Help Dialog */}
      <Dialog
        open={helpOpen}
        onClose={handleHelpClose}
        aria-labelledby="help-dialog-title"
        fullWidth
        maxWidth="md" // Start with a larger preset
        sx={{
          '& .MuiDialog-paper': {
            width: '80%', // Adjust width as needed
            maxHeight: '90vh', // Optional: Adjust height if necessary
          },
        }}
      >
        <DialogTitle id="help-dialog-title">Telemetry Data Help</DialogTitle>
        <DialogContent>
          {selectedHelpImage === null ? (
            // Display Chips if no image is selected
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                gap: 2,
                flexWrap: 'wrap',
                mt: 2,
              }}
            >
              <Chip
                label="Starlane"
                onClick={() => handleChipClick('/images/starlane_mobile.jpg', '/images/starlane.jpg')} 
     
                clickable
                sx={{
                  backgroundColor: 'rgba(0, 0, 0, 0.08)',
                  '&:hover': {
                    backgroundColor: 'rgba(0, 0, 0, 0.12)',
                  },
                  height: '48px',  
                  fontSize: '1.2rem',       // Increase font size
                  padding: '12px 24px',
                }}
              />
              <Chip
                label="Unipro"
                onClick={() => handleChipClick('/images/unipro_mobile.jpg', '/images/unipro.jpg')} 
    
                clickable
                sx={{
                  backgroundColor: 'rgba(0, 0, 0, 0.08)',
                  '&:hover': {
                    backgroundColor: 'rgba(0, 0, 0, 0.12)',
                  },
                  height: '48px',  
                  fontSize: '1.2rem',       // Increase font size
                  padding: '12px 24px',
                }}
              />
            </Box>
          ) : (
            // Display the selected image with a back button
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                mt: 2,
          
              }}
            >
              <img
                src={selectedHelpImage}
                alt="Help Illustration"
                style={{ width: '100%', borderRadius: '12px'}}
              />
<Button
  onClick={handleBackToChips}
  sx={{
    backgroundColor: 'rgba(0, 0, 0, 0.08)',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.12)',
    },
    height: '38px',
    fontSize: '1.0rem',       // Dimensione del font aumentata
    padding: '12px 24px',
    mt: '20px',                // Margine superiore di 20px
    fontWeight: 'normal',      // Imposta il peso del font su normale per rimuovere il grassetto
    color: 'black',            // Assicura che il testo sia di colore nero
  }}
>
  Back
</Button>

</Box>

          )}
        </DialogContent>
      </Dialog>

      {/* Uncomment if needed */}
      {/* <FilesRecap refreshList={refreshUploadList} onComplete={() => handleUploadComplete(false)} /> */}
    </Box>
  );
};

export default observer(PieChartWithSelect);
