import "../components.css";
import Mission from "../Mission";
import HeroSection from "../HeroSection";
import HowItWorksDriver from "../HowItWorksDriver";

import { Container } from "react-bootstrap";
import Footer from "../Footer";
import LearnForm from "../LearnForm";
import HowItWorksManager from "../HowItWorksManager";
import Infinitescroll from "../Infinitescroll";
import AskAiMotor from "../AskAiMotor";
import AiUses from "../AiUses";
import ChatBot from "./ChatBot";
import SecondPartHome from "../SecondPartHome";
import ThirdPartHome from "../ThirdPartHome";
// import HomeStepper from "../HomeStepper" // remove for old landing;
import CallToAction from "./CallToAction";
function Home() {
  return (
    <>
      <Container fluid className="homepage">
        <HeroSection />

      </Container>

      <Infinitescroll/>
      <AskAiMotor/>



      <SecondPartHome/>
      <ThirdPartHome/>
   
 

      <CallToAction/>
      <Footer />
    </>
  );
  // }
}
export default Home;
