import 'components/components.css';

import { Bar, BarChart, Legend, YAxis } from '@mui/x-charts';
import React, { useEffect, useState } from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';
import { DialogContent } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Typography } from '@mui/material';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import axios from "axios";
import { axisClasses } from '@mui/x-charts';
import myUserStore from '../../../stores/UserStore';
import { observer } from "mobx-react-lite"
import { sortBy } from 'lodash';
import { styled } from '@mui/material/styles';
import engine from '../TryFiles/engine_file.json';
const uniqueValues = (data, key) => {
    const unique = new Set(data.map(item => item[key]));
    return [...unique];
};




const MyComponent = ({ driver, race, day, fullName, showTable }) => {

    const mapCarburetor = (value) => {
        switch (parseInt(value)) {
            case 1:
                return "Tilltson (Junior)";
            case 2:
                return "Ibea F3";
            case 3:
                return "Ibea F6";
            case 4:
                return "TM";
            case 5:
                return "Tilltson New (Senior)";
            case 6:
                return "Tilltson Old (Senior)";

            default:
                return "Unknown";
        }
    };
    console.log('showTable', showTable)
    console.log('fullname', fullName);
    console.log(driver);
    const selectedName = driver.length > 0 ? fullName.filter(dataItem => driver.includes(dataItem.email)) : fullName;
    console.log("Selected Name", selectedName);
    console.log('Recived day:', day);
    const [selectedData, setSelectedData] = useState([]);
    const [crownVisible, setcrownVisible] = useState(showTable ? true : false);
    const [pinionVisible, setPinionVisible] = useState(showTable ? true : false);
    const [carburetorVisible, setCarburetorVisible] = useState(showTable ? true : false);
    const [temperatureVisible, setTemperatureVisible] = useState(showTable ? true : false);
    const [EngineNumberVisible, setEngineNumberVisible] = useState(showTable ? true : false);
    const [isHidden, setIsHidden] = useState(false);
    const [racingData, setRacingData] = useState([]);
    const [sessionNumber, setSessionNumber] = useState("");
    const [isMobile, setIsMobile] = useState(false);
    const [isSmall, setIsSmall] = useState(false);
    const [chartWidth, setChartWidth] = useState(window.innerWidth);
    console.log(chartWidth)

    let message;

    if (!race) {
        message = 'Please select a race';
    } else if (!day) {
        message = 'Please select a day';
    }

    const filterDataByDriverSessions = (data) => {
        if (driver?.length > 0) {
            return data.filter(item =>
                driver.includes(item.email) &&
                item.day == day
            );
        } else {
            return data.filter(item =>
                item.day == day
            );

        }

    };


    const extractModifiedLaptime = (racingData) => {
        const modifiedRacingData = racingData.map((item) => {
            const laptimeString = item.laptime;
            if (typeof laptimeString !== 'string') {
                console.error("laptimeString is not a string:", laptimeString);
                return null; // o qualsiasi altro valore significativo
            }
            const [hours, minutes, seconds] = laptimeString.split(":");
            const totalSeconds = parseInt(hours) * 60 + parseInt(minutes) + 0.01 * parseInt(seconds);
            const laptimeCalc = Math.round(totalSeconds * 1000) / 1000;

            // Restituisci un nuovo oggetto con laptimeCalc aggiunto
            return {
                ...item,
                laptimeCalc
            };
        }).filter(item => item !== null); // Rimuovi i valori nulli, se presenti

        return modifiedRacingData;
    };

    // Esempio di utilizzo
    const modifiedRacingData = extractModifiedLaptime(racingData);
    const racingData2 = calculateStats(modifiedRacingData);

    console.log("Racing Data  without stats", modifiedRacingData);
    console.log("Racing Data 1 with stats", racingData2);
    //const selectedTeam = JSON.parse(localStorage.getItem("selectedTeam"));
    const selectedTeam = myUserStore.getSelectedTeam;
    console.log(selectedTeam)
    useEffect(() => {
        const filterEngine = filterDataByDriverSessions(engine);
        console.log('filterEngine', filterEngine)
        const datasetWithStats = calculateStats(filterEngine); // Calculate statistics
        setRacingData(datasetWithStats); // Update state with dataset with statistics

        setcrownVisible(showTable ? true : false);
        setPinionVisible(showTable ? true : false);
        setTemperatureVisible(showTable ? true : false);
        setEngineNumberVisible(showTable ? true : false);
        setCarburetorVisible(showTable ? true : false);


    }, [driver, race, day, showTable]);



    console.log("full name given", fullName);



    const handleButtonClick = (buttonType) => {
        // Update the visibility of the clicked button
        if (buttonType === 'crown') {
            setcrownVisible(!crownVisible);
        } else if (buttonType === 'pinion') {
            setPinionVisible(!pinionVisible);
        } else if (buttonType === 'carburetor') {
            setCarburetorVisible(!carburetorVisible);
        } else if (buttonType === 'temperature') { // Handle temperature button click
            setTemperatureVisible(!temperatureVisible);
        } else if (buttonType === 'engineNumber') { // Handle temperature button click
            setEngineNumberVisible(!EngineNumberVisible);
        }
        setButtonVisibility((prevVisibility) => ({
            ...prevVisibility,
            [buttonType]: !prevVisibility[buttonType],
        }));
    };
    const [buttonVisibility, setButtonVisibility] = useState({
        crown: false,
        pinion: false,
        carburetor: false,
        temperature: false,
        engineNumber: false,
    });

    // const filteredData = dataset1.filter(item =>
    //   (!driver || (item.mail && driver.includes(item.mail)))
    // );


    useEffect(() => {
        // Create an object to store data for each unique email and session number combination
        const emailSessionMap = new Map();

        // const filteredData = dataset1.filter(item =>
        //   (!driver || (item.mail && driver.includes(item.mail)))
        // );


        // Iterate through the filtered data to populate the email session map
        racingData2.forEach(item => {
            const { email, session_number } = item;
            const key = `${email}-${session_number}`;



            if (!emailSessionMap.has(key)) {
                emailSessionMap.set(key, { email: item.email, session_number });
            }

            const sessionData = emailSessionMap.get(key);


            // Concatenate the values if the field already exists
            if (crownVisible && sessionData.crown) {
                sessionData.crown += `  |  ${item.crown}`;
            } else if (crownVisible) {
                sessionData.crown = `${item.crown}`;
            }

            if (pinionVisible && sessionData.pinion) {
                sessionData.pinion += `  |  ${item.pinion}`;
            } else if (pinionVisible) {
                sessionData.pinion = `${item.pinion}`;
            }

            if (carburetorVisible && sessionData.carburetor) {
                sessionData.carburetor += ` |   ${item.carburetor}`;
            } else if (carburetorVisible) {
                sessionData.carburetor = `${item.carburetor}`;
            }
            if (temperatureVisible && sessionData.temperature) {
                sessionData.temperature += `    ${item.temperature}`;
            } else if (temperatureVisible) {
                sessionData.temperature = `${item.temperature}`;
            }

            if (temperatureVisible && sessionData.laptime) {
                sessionData.laptime += `    ${item.laptime}`;
            }
            else if (temperatureVisible) {
                sessionData.laptime = `${item.laptime}`;
            }
            if (EngineNumberVisible && sessionData.engine_number) {
                sessionData.engine_number += `  |  ${item.engine_number}`;
            }
            else if (EngineNumberVisible) {
                sessionData.engine_number = `${item.engine_number}`;
            }

            emailSessionMap.set(key, sessionData);
        });

        // Convert the map values to an array of selected data
        const newData = Array.from(emailSessionMap.values());
        setSelectedData(newData);
    }, [crownVisible, pinionVisible, carburetorVisible, temperatureVisible, racingData, EngineNumberVisible]);
    console.log('sd', selectedData);




    function calculateStats(dataset) {
        const stats = {};

        dataset.forEach(item => {
            const session_number = item.session_number;

            if (!stats[session_number]) {
                stats[session_number] = {
                    min_rpm: {
                        min: Number.MAX_SAFE_INTEGER,
                        max: Number.MIN_SAFE_INTEGER,
                        sum: 0,
                        count: 0
                    },
                    laptimeCalc: {
                        min: Number.MAX_SAFE_INTEGER,
                        max: Number.MIN_SAFE_INTEGER,
                        sum: 0,
                        count: 0
                    },
                    min_exhaust: {
                        min: Number.MAX_SAFE_INTEGER,
                        max: Number.MIN_SAFE_INTEGER,
                        sum: 0,
                        count: 0
                    },
                    max_exhaust: {
                        min: Number.MAX_SAFE_INTEGER,
                        max: Number.MIN_SAFE_INTEGER,
                        sum: 0,
                        count: 0
                    },
                    max_speed: {
                        min: Number.MAX_SAFE_INTEGER,
                        max: Number.MIN_SAFE_INTEGER,
                        sum: 0,
                        count: 0
                    },
                    temperature: {
                        min: Number.MAX_SAFE_INTEGER,
                        max: Number.MIN_SAFE_INTEGER,
                        sum: 0,
                        count: 0
                    }
                };
            }

            const sessionStats = stats[session_number];

            // Update min_rpm stats
            if (item.min_rpm < sessionStats.min_rpm.min) {
                sessionStats.min_rpm.min = item.min_rpm;
            }
            if (item.min_rpm > sessionStats.min_rpm.max) {
                sessionStats.min_rpm.max = item.min_rpm;
            }
            sessionStats.min_rpm.sum += item.min_rpm;
            sessionStats.min_rpm.count++;

            // Update laptime stats
            if (item.laptimeCalc < sessionStats.laptimeCalc.min) {
                sessionStats.laptimeCalc.min = item.laptimeCalc;
            }
            if (item.laptimeCalc > sessionStats.laptimeCalc.max) {
                sessionStats.laptimeCalc.max = item.laptimeCalc;
            }
            sessionStats.laptimeCalc.sum += item.laptimeCalc;
            sessionStats.laptimeCalc.count++;

            // Update min_exhaust stats
            if (item.min_exhaust < sessionStats.min_exhaust.min) {
                sessionStats.min_exhaust.min = item.min_exhaust;
            }
            if (item.min_exhaust > sessionStats.min_exhaust.max) {
                sessionStats.min_exhaust.max = item.min_exhaust;
            }
            sessionStats.min_exhaust.sum += item.min_exhaust;
            sessionStats.min_exhaust.count++;

            // Update max_exhaust stats
            if (item.max_exhaust < sessionStats.max_exhaust.min) {
                sessionStats.max_exhaust.min = item.max_exhaust;
            }
            if (item.max_exhaust > sessionStats.max_exhaust.max) {
                sessionStats.max_exhaust.max = item.max_exhaust;
            }
            sessionStats.max_exhaust.sum += item.max_exhaust;
            sessionStats.max_exhaust.count++;

            // Update max_speed stats
            if (item.max_speed < sessionStats.max_speed.min) {
                sessionStats.max_speed.min = item.max_speed;
            }
            if (item.max_speed > sessionStats.max_speed.max) {
                sessionStats.max_speed.max = item.max_speed;
            }
            sessionStats.max_speed.sum += item.max_speed;
            sessionStats.max_speed.count++;

            // Upndate temperature stats
            if (item.temperature < sessionStats.temperature.min) {
                sessionStats.temperature.min = item.temperature;
            }
            if (item.temperature > sessionStats.temperature.max) {
                sessionStats.temperature.max = item.temperature;
            }
            sessionStats.temperature.sum += item.temperature;
            sessionStats.temperature.count++;
        });

        // Calculate averages and other metrics for each session
        for (const session_number in stats) {
            const sessionStats = stats[session_number];

            // Calculate averages for min_rpm and laptime
            sessionStats.min_rpm.avg = Math.round((sessionStats.min_rpm.sum / sessionStats.min_rpm.count) * 1000) / 1000;
            sessionStats.laptimeCalc.avg = Math.round((sessionStats.laptimeCalc.sum / sessionStats.laptimeCalc.count) * 1000) / 1000;
            sessionStats.min_exhaust.avg = Math.round((sessionStats.min_exhaust.sum / sessionStats.min_exhaust.count) * 1000) / 1000;
            sessionStats.max_exhaust.avg = Math.round((sessionStats.max_exhaust.sum / sessionStats.max_exhaust.count) * 1000) / 1000;
            sessionStats.max_speed.avg = Math.round((sessionStats.max_speed.sum / sessionStats.max_speed.count) * 1000) / 1000;


            // Calculate additional metrics for min_rpm and laptime
            // Calculate additional metrics for min_rpm, laptime, min_exhaust, max_exhaust, and max_speed
            sessionStats.min_rpm.avg_rpm1 = Math.round((sessionStats.min_rpm.avg - sessionStats.min_rpm.min) * 1000) / 1000;
            sessionStats.min_rpm.max_rpm1 = Math.round((sessionStats.min_rpm.max - sessionStats.min_rpm.avg) * 1000) / 1000;
            sessionStats.laptimeCalc.avg_lap1 = Math.round((sessionStats.laptimeCalc.avg - sessionStats.laptimeCalc.min) * 1000) / 1000;
            sessionStats.laptimeCalc.max_lap1 = Math.round((sessionStats.laptimeCalc.max - sessionStats.laptimeCalc.avg) * 1000) / 1000;

            sessionStats.min_exhaust.avg_exhaust1 = Math.round((sessionStats.min_exhaust.avg - sessionStats.min_exhaust.min) * 1000) / 1000;
            sessionStats.min_exhaust.max_exhaust1 = Math.round((sessionStats.min_exhaust.max - sessionStats.min_exhaust.avg) * 1000) / 1000;
            sessionStats.max_exhaust.avg_exhaust1 = Math.round((sessionStats.max_exhaust.avg - sessionStats.max_exhaust.min) * 1000) / 1000;
            sessionStats.max_exhaust.max_exhaust1 = Math.round((sessionStats.max_exhaust.max - sessionStats.max_exhaust.avg) * 1000) / 1000;
            sessionStats.max_speed.avg_speed1 = Math.round((sessionStats.max_speed.avg - sessionStats.max_speed.min) * 1000) / 1000;
            sessionStats.max_speed.max_speed1 = Math.round((sessionStats.max_speed.max - sessionStats.max_speed.avg) * 1000) / 1000;

        }

        // Convert stats object to array
        const statsArray = Object.values(stats);


        const datasetWithStats = dataset.map(item => {
            return {
                ...item,
                stats: stats[item.session_number]
            };
        });

        return datasetWithStats;
    }


    const xLabels = [
        'Session 1',
        'Session 2',
        'Session 3',
        'Session 4',
        'Session 5',
        'Session 6',

    ];

    const seriesA = {
        data: [5, 2.5, 1],
        label: 'Series A',
    };
    const seriesB = {
        data: [0, 0, 0], // Set all values to 0 to avoid stacking
        label: 'Series B (Reference)',
    };
    const seriesC = {
        data: [0, 0, 0], // Set all values to 0 to avoid stacking
        label: 'Series C (Reference)',
    };

    const [stackVisibility, setStackVisibility] = useState({
        stack1: true,
        stack2: true,
        stack3: true,
        stack4: true,
        stack5: true,
    });

    const LightTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: theme.palette.common.white,
            color: 'rgba(0, 0, 0, 0.87)',
            boxShadow: theme.shadows[1],
            fontSize: 11,
        },
    }));

    const handleToggleStack = (stack) => {
        setStackVisibility({ ...stackVisibility, [stack]: !stackVisibility[stack] });

    };
    const [activeButton, setActiveButton] = useState(null);
    let add;
    const valueFormatter = (value, add) => `${value + add}`;

    const theme = createTheme({
        palette: {
            primary: { main: '#00897B', light: '#B2DFDB', dark: '#004D40' },
            secondary: { main: '#0057B2', },
            success: { main: '#7B1FA2', },
            info: { main: '#AD1457' },
            error: { main: '#E64A19' }

        },
    });

    const sessionNumbers = []; // Array per tenere traccia dei session number già visti
    let numberOfCells = 0;
    const sortedData = racingData2.sort((a, b) => a.session_number - b.session_number);
    const racingData1 = [...sortedData];

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 750); // Adjust breakpoint as needed
        };

        window.addEventListener('resize', handleResize);
        handleResize();

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        const handleResize = () => {
            setIsSmall(window.innerWidth <= 1030); // Adjust breakpoint as needed
        };

        window.addEventListener('resize', handleResize);
        handleResize();

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    /*racingData1.sort((a, b) => a.session_number - b.session_number);
    console.log(racingData1);
    const maxCrownItem = racingData1.reduce((maxItem, currentItem) => {
      return currentItem.crown.length > maxItem.crown.length ? currentItem : maxItem;
    }, racingData1[0]);
    
    const maxCrownLaptime = maxCrownItem.laptime; */
    const handleZoomIn = () => {
        setChartWidth((prevWidth) => prevWidth + 100);
    };

    const handleZoomOut = () => {
        setChartWidth((prevWidth) => Math.max(prevWidth - 100, 100));
    };

    return (


        <div className='frame'>


            {/*<>
                <div className='textEngine'>

                      <Typography Variant="overline" Gutter Bottom='False' Content="Typography" className='customizeText'> CUSTOMIZE THE VISUALIZATION </Typography>
                    <div className='engineButtons'>
                        <ThemeProvider theme={theme}>
                            <Chip onClick={() => handleToggleStack('stack1')} label="MIN RPM" color='primary' size="medium" variant={stackVisibility['stack1'] ? "filled" : "outlined"} checked={stackVisibility.stack1} />
                            <Chip onClick={() => handleToggleStack('stack2')} label="LAP TIME" color="secondary" size="medium" variant={stackVisibility['stack2'] ? "filled" : "outlined"} checked={stackVisibility.stack2} />
                            <Chip onClick={() => handleToggleStack('stack5')} label="MAX SPEED" color="error" size="medium" variant={stackVisibility['stack5'] ? "filled" : "outlined"} checked={stackVisibility.stack5} />
                            <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '10px' }}>
                                <ZoomInIcon variant="contained" onClick={handleZoomOut} style={{ cursor: 'pointer', marginRight: '10px', width: 40, height: 40 }} />

                                <ZoomOutIcon variant="contained" onClick={handleZoomIn} style={{ cursor: 'pointer', marginRight: '10px', width: 40, height: 40 }} />

                            </div>
                        </ThemeProvider>
                    </div>
                </div>

                {!(race && day) ? (
                    <div className="grid-demo-no-data-container">
                        {message && (
                            <>
                                <p className="grid-demo-no-data-text">{message}</p>
                                <i className="grid-demo-info-icon fas fa-info-circle"></i>
                            </>
                        )}
                    </div>)

                    : (<div className='graphContainer' style={{ display: 'flex', justifyContent: 'center' }}>


                    <BarChart
                            tooltip={{ trigger: 'item' }}
                            slotProps={{ legend: { hidden: true } }}
                            axisHighlight={{
                                y: 'line', // Or 'none'
                                rightAxis: 'line'
                            }}
                            width={chartWidth}
                            height={!isSmall ? 650 : 500}
                            barwidth={300000000}
                            barLabel="value"
                            series={[
                                stackVisibility.stack1 && racingData1.length > 0 && {
                                    dataset: { racingData1 },
                                    data: racingData1.map((item) => Math.round((item.stats.min_rpm.max * 33 / 100) * 1000) / 1000),
                                    stack: 'stack1',
                                    yAxisKey: 'leftAxisId',
                                    label: 'Min RPM min',
                                    color: '#B2DFDB',


                                    valueFormatter: (value) => {
                                        const containsItem = racingData1.find(dataItem => dataItem.stats.min_rpm.max === Math.round((value * 100 / 33) * 1000) / 1000);
                                        const correspondingItem = racingData1.find(dataItem => dataItem.min_rpm === containsItem.stats.min_rpm.min);
                                        const name = racingData1.find(dataItem => dataItem.email === correspondingItem.email);
                                        return ` ( ${name.name} ${name.surname})  ${correspondingItem.min_rpm}`;
                                    },
                                    id: 'pvId',
                                },
                                stackVisibility.stack1 && racingData1.length > 0 && {

                                    dataset: { racingData1 },
                                    data: racingData1.map(item => Math.round((item.stats.min_rpm.max * 33 / 100) * 1000) / 1000),
                                    valueFormatter: (value) => {
                                        const correspondingItem = racingData1.find(dataItem => dataItem.stats.min_rpm.max === Math.round((value * 100 / 33) * 1000) / 1000);
                                        return ` ${correspondingItem.stats.min_rpm.avg}`;
                                    },
                                    stack: 'stack1',
                                    yAxisKey: 'leftAxisId',
                                    label: 'Min RPM avg',
                                    color: '#00897B',

                                },
                                stackVisibility.stack1 && racingData1.length > 0 && {
                                    dataset: { racingData1 },
                                    data: racingData1.map(item => Math.round((item.stats.min_rpm.max * 33 / 100) * 1000) / 1000),
                                    valueFormatter: (value) => {
                                        const containsItem = racingData1.find(dataItem => dataItem.stats.min_rpm.max === Math.round((value * 100 / 33) * 1000) / 1000);
                                        const correspondingItem = racingData1.find(dataItem => dataItem.min_rpm === containsItem.stats.min_rpm.max);
                                        const name = racingData1.find(dataItem => dataItem.email === correspondingItem.email);
                                        return `  ( ${name.name} ${name.surname}) ${correspondingItem.stats.min_rpm.max}`;
                                    },
                                    stack: 'stack1',
                                    yAxisKey: 'leftAxisId',
                                    label: 'Min RPM max',
                                    color: '#004D40',

                                },
                                stackVisibility.stack2 && racingData1.length > 0 && {
                                    dataset: { racingData1 },
                                    data: racingData1.map((item) => Math.round((item.stats.laptimeCalc.max * 33 / 100) * 1000) / 1000),
                                    stack: 'stack2',
                                    yAxisKey: (stackVisibility['stack2'] && !stackVisibility['stack1'] && !stackVisibility['stack5']) || (stackVisibility['stack2'] && !stackVisibility['stack1'] && stackVisibility['stack5']) ? 'leftAxisId' : 'rightAxisId',
                                    label: 'Lap Time min',
                                    color: '#BBDEFB',
                                    valueFormatter: (value) => {

                                        const rem = Math.round((value * 100 / 33) * 100) / 100;
                                        const containsItem = racingData1.find(dataItem => dataItem.stats.laptimeCalc.max === Math.round((value * 100 / 33) * 100) / 100);
                                        const correspondingItem = racingData1.find(dataItem => dataItem.laptimeCalc === containsItem.stats.laptimeCalc.min);
                                        const name = racingData1.find(dataItem => dataItem.email === correspondingItem.email);
                                        return ` ( ${name.name} ${name.surname})  ${correspondingItem.laptime}`;
                                    },
                                },
                                stackVisibility.stack2 && racingData1.length > 0 && {
                                    dataset: { racingData1 },
                                    data: racingData1.map(item => Math.round((item.stats.laptimeCalc.max * 33 / 100) * 1000) / 1000),
                                    valueFormatter: (value) => {
                                        const containsItem = racingData1.find(dataItem => dataItem.stats.laptimeCalc.max === Math.round((value * 100 / 33) * 100) / 100);
                                        return `        ${containsItem.stats.laptimeCalc.avg}`;
                                    },
                                    stack: 'stack2',
                                    yAxisKey: (stackVisibility['stack2'] && !stackVisibility['stack1'] && !stackVisibility['stack5']) || (stackVisibility['stack2'] && !stackVisibility['stack1'] && stackVisibility['stack5']) ? 'leftAxisId' : 'rightAxisId',
                                    label: 'Lap Time avg',
                                    color: '#1E88E5',
                                },
                                stackVisibility.stack2 && racingData1.length > 0 && {
                                    dataset: { racingData1 },
                                    data: racingData1.map(item => Math.round((item.stats.laptimeCalc.max * 33 / 100) * 1000) / 1000),
                                    valueFormatter: (value, index) => {

                                        const rem = Math.round((value * 100 / 33) * 100) / 100;
                                        const containsItem = racingData1.find(dataItem => dataItem.stats.laptimeCalc.max === Math.round((value * 100 / 33) * 100) / 100);
                                        const correspondingItem = racingData1.find(dataItem => dataItem.laptimeCalc === containsItem.stats.laptimeCalc.max);


                                        const name = racingData1.find(dataItem => dataItem.email === correspondingItem.email);
                                        return ` ( ${name.name} ${name.surname}) ${correspondingItem.laptime}`;
                                    },
                                    stack: 'stack2',
                                    yAxisKey: (stackVisibility['stack2'] && !stackVisibility['stack1'] && !stackVisibility['stack5']) || (stackVisibility['stack2'] && !stackVisibility['stack1'] && stackVisibility['stack5']) ? 'leftAxisId' : 'rightAxisId',
                                    label: 'Lap Time max',
                                    color: '#0D47A1',

                                },



                                stackVisibility.stack5 && racingData1.length > 0 && {
                                    dataset: { racingData1 },
                                    data: racingData1.map((item) => Math.round((item.stats.max_speed.max * 33 / 100) * 1000) / 1000),
                                    stack: 'stack5',
                                    valueFormatter: (value) => {
                                        const containsItem = racingData1.find(dataItem => dataItem.stats.max_speed.max === Math.round((value * 100 / 33) * 1000) / 1000);
                                        const correspondingItem = racingData1.find(dataItem => dataItem.max_speed === containsItem.stats.max_speed.min);
                                        const name = racingData1.find(dataItem => dataItem.email === correspondingItem.email);
                                        return ` ( ${name.name} ${name.surname}) ${correspondingItem.max_speed}`;
                                    },
                                    yAxisKey: (!stackVisibility['stack2'] && !stackVisibility['stack1'] && stackVisibility['stack5']) || (stackVisibility['stack2'] && !stackVisibility['stack1'] && stackVisibility['stack5']) ? 'leftAxisId' : 'rightAxisId',
                                    label: 'Max Speed min',
                                    color: '#FFCCBC',
                                },
                                stackVisibility.stack5 && racingData1.length > 0 && {
                                    dataset: { racingData1 },
                                    data: racingData1.map(item => Math.round((item.stats.max_speed.max * 33 / 100) * 1000) / 1000),
                                    valueFormatter: (value) => {
                                        const containsItem = racingData1.find(dataItem => dataItem.stats.max_speed.max === Math.round((value * 100 / 33) * 1000) / 1000);
                                        return `  ${containsItem.stats.max_speed.avg}`;
                                    },
                                    stack: 'stack5',
                                    yAxisKey: (!stackVisibility['stack2'] && !stackVisibility['stack1'] && stackVisibility['stack5']) || (stackVisibility['stack2'] && !stackVisibility['stack1'] && stackVisibility['stack5']) ? 'leftAxisId' : 'rightAxisId',
                                    label: 'Max Speed avg',
                                    color: '#F4511E',
                                },
                                stackVisibility.stack5 && racingData1.length > 0 && {
                                    dataset: { racingData1 },
                                    data: racingData1.map(item => Math.round((item.stats.max_speed.max * 33 / 100) * 1000) / 1000),
                                    valueFormatter: (value) => {
                                        const containsItem = racingData1.find(dataItem => dataItem.stats.max_speed.max === Math.round((value * 100 / 33) * 1000) / 1000);
                                        const correspondingItem = racingData1.find(dataItem => dataItem.max_speed === containsItem.stats.max_speed.max);
                                        const name = racingData1.find(dataItem => dataItem.email === correspondingItem.email);

                                        return `  ( ${name.name} ${name.surname}) ${correspondingItem.stats.max_speed.max}`;
                                    },
                                    stack: 'stack5',
                                    yAxisKey: (!stackVisibility['stack2'] && !stackVisibility['stack1'] && stackVisibility['stack5']) || (stackVisibility['stack2'] && !stackVisibility['stack1'] && stackVisibility['stack5']) ? 'leftAxisId' : 'rightAxisId',
                                    label: 'Max Speed max',
                                    color: '#BF360C',
                                }
                            ].filter(Boolean)}
                            xAxis={[{ data: racingData1.map((item) => item.session_number), scaleType: 'band' }]}
                            yAxis={[
                                { id: 'leftAxisId', domain: [-100, 999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999000000000000000000] }, // Adjust the domain values as needed
                                { id: 'rightAxisId', domain: [-100, 999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999000000000000000000] }, // Adjust the domain values as needed
                            ]}
                            rightAxis="rightAxisId"





                        >

                        </BarChart>
                    </div>)} </>*/}
            {!(race && day) && (
                <div className="grid-demo-no-data-container">
                    {message && (
                        <>
                            <p className="grid-demo-no-data-text">{message}</p>
                            <i className="grid-demo-info-icon fas fa-info-circle"></i>
                        </>
                    )}
                </div>)}


            {racingData1?.length > 0 && (race && day) && (<div>
                <div className='custom-table'>
                    <Typography Variant="overline" Gutter Bottom='False' Content="Typography" className='customizeText'> CUSTOMIZE THE TABLE   </Typography>


                </div>

                <div className='table-buttons'>
                    <ThemeProvider theme={theme}>
                        <Box gap={4}>
                            <Chip onClick={() => handleButtonClick('crown')} label="CROWN" sx={{ margin: 1 }} color='default' size="medium" variant={crownVisible ? "contained" : "outlined"} />
                            <Chip onClick={() => handleButtonClick('pinion')} label="PINION" sx={{ margin: 1 }} color="default" size="medium" variant={pinionVisible ? "contained" : "outlined"} />
                            <Chip onClick={() => handleButtonClick('carburetor')} label="CARBURETOR" sx={{ margin: 1 }} color="default" size="medium" variant={carburetorVisible ? "contained" : "outlined"} />
                            {!isNaN(selectedData.temperature) && (<Chip onClick={() => handleButtonClick('temperature')} label="TEMPERATURE" sx={{ margin: 1 }} color="default" size="medium" variant={temperatureVisible ? "contained" : "outlined"} />)}
                            <Chip onClick={() => handleButtonClick('engineNumber')} label="ENGINE NUMBER" sx={{ margin: 1 }} color="default" size="medium" variant={EngineNumberVisible ? "contained" : "outlined"} />
                        </Box>
                    </ThemeProvider>
                </div>


                <TableContainer>
                    <Table onChange>
                        <TableHead>
                            <TableRow sx={{ background: 'rgba(0, 0, 0, 0.00)', border: '1px solid lightgrey', padding: '20px' }}>
                                <TableCell />
                                {selectedData.map((item, index) => {

                                    if (sessionNumbers.includes(item.session_number)) {
                                        return null;
                                    } else {
                                        sessionNumbers.push(item.session_number);
                                        numberOfCells++;
                                        return (
                                            <TableCell key={`session-number-${index}`}>
                                                Session Number {item.session_number}
                                                <div style={{ display: 'row', color: 'black', fontWeight: 'normal' }}>
                                                    {!isNaN(item.temperature) && (<Typography>
                                                        {item.temperature} °
                                                    </Typography>)}
                                                </div>
                                            </TableCell>
                                        );
                                    }
                                })}

                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                {/*<TableCell sx={{ borderLeft: '1px solid lightgrey' }} />
              {Array.from({ length: numberOfCells }, (_, index) => (
                <TableCell key={index} sx={{ borderRight: '1px solid lightgrey' }}>
                  Runs

                </TableCell>
              ))}*/}
                            </TableRow>
                            {crownVisible && <TableCell align='right' sx={{ fontWeight: 'bold', borderLeft: '1px solid lightgrey', padding: '12px' }}>CROWN</TableCell>}
                            {crownVisible && <TableCell align='right' colSpan={selectedData.length + 1} sx={{ fontWeight: 'bold', borderRight: '1px solid lightgrey', padding: '12px' }}></TableCell>}


                            {crownVisible && selectedName && selectedName.map((selectedName, index) => (

                                <TableRow sx={{ border: '1px solid lightgrey' }} key={`driver-${index}`}>
                                    <TableCell sx={{ border: '1px solid lightgrey' }}>{selectedName.surname}</TableCell>
                                    {selectedData.filter(item => item.email === selectedName.email).map((item, index) => (
                                        item.crown && item.crown.includes('|') ? (
                                            <LightTooltip placement='top' title={"More runs in the same session"} arrow>
                                                <TableCell key={`driver-${index}-crown`}>{item.crown}</TableCell>
                                            </LightTooltip>
                                        ) : (
                                            <TableCell key={`driver-${index}-crown`}>{item.crown}</TableCell>
                                        )
                                    ))}
                                </TableRow>
                            ))}

                            {pinionVisible && <TableCell align='right' sx={{ fontWeight: 'bold', borderLeft: '1px solid lightgrey', padding: '12px' }}>PINION</TableCell>}
                            {pinionVisible && <TableCell align='right' colSpan={selectedData.length + 1} sx={{ fontWeight: 'bold', borderRight: '1px solid lightgrey', padding: '12px' }}></TableCell>}
                            {pinionVisible && selectedName && selectedName.map((selectedName, index) => (
                                <TableRow sx={{ border: '1px solid lightgrey' }} key={`driver-${index}`}>
                                    <TableCell sx={{ border: '1px solid lightgrey' }}>{selectedName.surname}</TableCell>
                                    {selectedData.filter(item => item.email === selectedName.email).map((item, index) => (
                                        item.pinion && item.pinion.includes('|') ? (
                                            <LightTooltip placement='top' title={"More runs in the same session"} arrow>
                                                <TableCell key={`driver-${index}-pinion`}>{item.pinion}</TableCell>
                                            </LightTooltip>
                                        ) : (
                                            <TableCell key={`driver-${index}-pinion`}>{item.pinion}</TableCell>
                                        )
                                    ))}
                                </TableRow>
                            ))}

                            {carburetorVisible && <TableCell align='right' sx={{ fontWeight: 'bold', borderLeft: '1px solid lightgrey', padding: '12px' }}>CARBURETOR</TableCell>}
                            {carburetorVisible && <TableCell align='right' colSpan={selectedData.length + 1} sx={{ fontWeight: 'bold', borderRight: '1px solid lightgrey', padding: '12px' }}></TableCell>}
                            {carburetorVisible && selectedName && selectedName.map((selectedName, index) => (
                                <TableRow sx={{ border: '1px solid lightgrey' }} key={`driver-${index}`}>
                                    <TableCell sx={{ border: '1px solid lightgrey' }}>{selectedName.surname}</TableCell>
                                    {selectedData.filter(item => item.email === selectedName.email).map((item, index) => (
                                        item.carburetor && item.carburetor.includes('|') ? (
                                            <LightTooltip placement='top' title={"More runs in the same session"} arrow>
                                                <TableCell key={`driver-${index}-carburetor`}>{item.carburetor}</TableCell>
                                            </LightTooltip>
                                        ) : (
                                            <TableCell key={`driver-${index}-carburetor`}>{item.carburetor}</TableCell>
                                        )
                                    ))}
                                </TableRow>
                            ))}

                            {EngineNumberVisible && <TableCell align='right' sx={{ fontWeight: 'bold', borderLeft: '1px solid lightgrey', padding: '12px' }}>ENGINE NUMBER</TableCell>}
                            {EngineNumberVisible && <TableCell align='right' colSpan={selectedData.length + 1} sx={{ fontWeight: 'bold', borderRight: '1px solid lightgrey', padding: '12px' }}></TableCell>}
                            {EngineNumberVisible && selectedName && selectedName.map((selectedName, index) => (
                                <TableRow sx={{ border: '1px solid lightgrey' }} key={`driver-${index}`}>
                                    <TableCell sx={{ border: '1px solid lightgrey' }}>{selectedName.surname}</TableCell>
                                    {selectedData.filter(item => item.email === selectedName.email).map((item, index) => (
                                        item.engine_number && item.engine_number.includes('|') ? (
                                            <LightTooltip placement='top' title={"More runs in the same session"} arrow>
                                                <TableCell key={`driver-${index}-engine_number`}>{item.engine_number}</TableCell>
                                            </LightTooltip>
                                        ) : (
                                            <TableCell key={`driver-${index}-engine_number`}>{item.engine_number}</TableCell>
                                        )
                                    ))}
                                </TableRow>
                            ))}



                        </TableBody>
                    </Table>
                </TableContainer>

            </div>)}

        </div>
    );
};

export default observer(MyComponent);

