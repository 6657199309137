import { Box, Button, Chip, Grid, Stack, Typography } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import ImageTextCard from 'components/Card/ImageText';
import { Link } from "react-router-dom";
import React from 'react';
import { styled } from '@mui/system';
import Footer from "../Footer";
const WhyTara = () => {
  const features = [
    // Your features can be added here if needed.
  ];

  const containerStyle = {
    margin: '0 auto',
    textAlign: 'center',
    backgroundColor: '#000',
    color: '#fff',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  };


  const containerStyle1 = {


    textAlign: 'center',
    backgroundColor: '#000',
    color: '#fff',
    boxSizing: 'border-box',

    flexDirection: 'column',
    alignItems: 'center',
    width: '100%', // Ensure full width
  };

  const heroStyle = {
    textAlign: 'left',
    background: '#3A49F9',
    padding: '4rem 2rem',
    borderRadius: '10px',
    boxShadow: '0 2px 4px rgba(255, 255, 255, 0.1)',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    color: '#fff',
    flexWrap: 'wrap',
    width: '100%',
    maxWidth: '1200px' // Ensures the hero section doesn't stretch too wide
  };

  const BackgroundContainer = styled(Box)(({ theme }) => ({
    background: 'linear-gradient(135deg, #3A49F9 0%, #4D5DFB 100%)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '30vh',
    color: '#fff',
    textAlign: 'center',
    flexDirection: 'column',
    position: 'relative',
    width: '100%', // Ensure full width
    margin: 0, // Remove margin
    padding: 0, // Remove padding
  }));
  
  const StyledButton = styled(Button)(({ theme }) => ({
    backgroundColor: '#fff',
    color: '#3A49F9',
    borderRadius: '25px',
    padding: '10px 20px',
    textTransform: 'none',
    fontWeight: 'bold',
    fontSize: '1rem',
    '&:hover': {
      backgroundColor: '#e0e0e0',
    },
  }));

  const heroTextContainer = {
    flex: '1',
    paddingRight: '2rem',
    minWidth: '300px',
    boxSizing: 'border-box'
  };

  const heroImageContainer = {
    flex: '1',
    textAlign: 'center',
    minWidth: '300px',
    boxSizing: 'border-box'
  };

  const heroImageStyle = {
    maxWidth: '100%',
    borderRadius: '10px',
    boxShadow: '0 2px 4px rgba(255, 255, 255, 0.1)'
  };

  const headlineStyle = {
    fontSize: '2rem',
    marginBottom: '1rem'
  };

  const subheadlineStyle = {
    fontSize: '1.2rem',
    marginBottom: '2rem',
    color: '#ccc'
  };

  const centeredSectionStyle = {
    textAlign: 'center',
    marginTop: '4rem',
    marginBottom: '4rem'
  };

  const additionalSectionStyle = {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    textAlign: 'left',
    flexWrap: 'wrap',
    width: '100%',
    maxWidth: '1200px' // Ensures the additional section doesn't stretch too wide
  };

  const additionalTextContainer = {
    flex: '1',
    paddingRight: '2rem',
    minWidth: '300px',
    boxSizing: 'border-box'
  };

  const additionalImageContainer = {
    flex: '1',
    textAlign: 'center',
    minWidth: '300px',
    boxSizing: 'border-box'
  };

  const additionalImageStyle = {
    maxWidth: '100%',
    borderRadius: '10px',
    boxShadow: '0 2px 4px rgba(255, 255, 255, 0.1)'
  };

  const tasksSectionStyle = {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: '4rem',
    textAlign: 'left',
    flexWrap: 'wrap',
    width: '100%',
    maxWidth: '1200px' // Ensures the tasks section doesn't stretch too wide
  };

  const tasksTextContainer = {
    flex: '1',
    paddingRight: '2rem',
    minWidth: '300px',
    boxSizing: 'border-box'
  };

  const tasksImageContainer = {
    flex: '1',
    textAlign: 'center',
    minWidth: '300px',
    boxSizing: 'border-box'
  };

  const tasksImageStyle = {
    maxWidth: '100%',
    borderRadius: '10px',
    boxShadow: '0 2px 4px rgba(255, 255, 255, 0.1)'
  };

  const gridStyle = {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
    gap: '2rem',
    marginTop: '2rem',
    width: '100%',
    maxWidth: '1200px' // Ensures the grid doesn't stretch too wide
  };

  const cardStyle = {
    background: '#444',
    padding: '1.5rem',
    borderRadius: '10px',
    boxShadow: '0 2px 4px rgba(255, 255, 255, 0.1)',
    transition: 'transform 0.3s ease',
    color: '#fff'
  };

  const iconStyle = {
    fontSize: '2rem',
    marginBottom: '1rem'
  };

  const titleStyle = {
    fontSize: '1.5rem',
    marginBottom: '1rem'
  };

  const descriptionStyle = {
    fontSize: '1rem',
    color: '#ccc'
  };

  const headingStyle = {
    marginTop: '4rem',
    fontSize: '2rem',
    color: '#fff'
  };

  const NewComponent = () => {

    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
      const handleResize = () => {
        setIsMobile(window.innerWidth <= 768); // Adjust breakpoint as needed
      };
  
      window.addEventListener('resize', handleResize);
      handleResize();
  
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);

    const newComponentStyles = {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      backgroundColor: '#333',
      borderRadius: '10px',
      overflow: 'hidden',
      marginBottom: '20px',
      width: '80%',
      maxWidth: '1200px',
      height: 'auto',
      margin: '20px 0',
    };
  
    const gifContainerStyles = {
      width: '50%',
      height: 'auto',
      display: 'flex',
    };
  
    const gifStyles = {
      width: '100%',
      height: 'auto',
      display: 'block',
    };
  
    const textContainerStyles = {
      width: '50%',
      color: 'white',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      padding: '20px',
    };
  
    const textMessageStyles = {
      fontSize: '30px',
      marginBottom: '10px',
    };
  
    const textDescriptionStyles = {
      fontSize: '16px',
    };
  
    const listContainerStyles = {
      width: '50%',
      color: 'white',
      padding: '20px',
      textAlign: 'left', // Ensures text alignment
    };
  
    const mediaQueryStyles = `
      @media (max-width: 768px) {
        .gif-container {
          display: none;
        }
        .text-container {
          width: 100%;
        }
      }
    `;
  
    const items = !isMobile ? (
      <>
        <style>{mediaQueryStyles}</style>
        <div style={newComponentStyles}>
          <div className="gif-container" style={gifContainerStyles}>
            <img src="images/team_arch.jpg" alt="GIF" style={gifStyles} />
          </div>
          <div className="text-container" style={listContainerStyles}>
            <div style={textMessageStyles}>Share</div>
            <div style={textDescriptionStyles}>
              By knowing all the team's setups in real-time, you can understand the changes needed for your Go-Kart and enhance your performance
            </div>
          </div>
        </div>
      </>
    ) : null;
    
    return items;
    
  };
  
  

  const NewComponent2 = () => {
    const newComponentStyles = {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      backgroundColor: '#333',
      borderRadius: '10px',
      overflow: 'hidden',
      marginBottom: '30px',
      width: '80%',
      maxWidth: '1200px',
      height: 'auto',
      marginTop: '30px',
    };

    const textMessageStyles = {
      fontSize: '30px',
      marginBottom: '10px',
    };

    const gifContainerStyles = {
      width: '50%',
      height: 'auto',
      display: 'flex',
    };

    const gifStyles = {
      width: '100%',
      height: 'auto',
      display: 'block',
    };

    const textContainerStyles = {
      width: '50%',
      color: 'white',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      padding: '20px',
    };

    const textDescriptionStyles = {
      fontSize: '16px',
    };

    const listContainerStyles = {
      width: '50%',
      color: 'white',
      padding: '20px',
      textAlign: 'left', // Ensures text alignment
    };

    return (
      <div style={newComponentStyles}>
        <div style={gifContainerStyles}>
          <img src={"videos/team_archive_1.gif"} alt="GIF" style={gifStyles} />
        </div>
        <div style={listContainerStyles}>
        <div style={textMessageStyles}>Table</div>
        <div style={textDescriptionStyles}>With more than one driver, the values entered by the selected drivers for each field are compared</div>
          
        </div>
      </div>
    );
  };

  return (
    <div style={containerStyle}>
      <div style={heroStyle}>
        <div style={heroTextContainer}>
          <h1 style={headlineStyle}>Effortlessly store and access your track data with our intuitive and advanced archive</h1>
          <p style={subheadlineStyle}>Data Archive represents your data, guiding you on what to do for each track</p>
        </div>
        <div style={heroImageContainer}>
          <img src={"videos/ARCHIVE.gif"} alt={"gif"} style={{ width: "100%", display: "flex" }} className="content-gif" />
        </div>
      </div>

      <div style={centeredSectionStyle}>
        <Stack direction="row" spacing={1} mb={3} justifyContent={'center'}>
          <Chip label="For Personal Space" id="For Riders" component="a" href="#For Riders" variant="outlined" color='primary' clickable /*sx={{color: 'white' }}*/ />
          <Chip label="For Racing Teams" component="a" href='#For Team Managers' variant="outlined" clickable sx={{ color: 'white' }} />
        </Stack>
        <h2 style={{ fontSize: '2rem', marginBottom: '1rem' }}>For each competition and race day you can access your data </h2>
      </div>

      <div style={additionalSectionStyle}>
        <div style={additionalTextContainer}>
          <h3 style={{ fontSize: '2rem', marginBottom: '1rem' }}>Delete</h3>
          <p style={{ fontSize: '1.2rem', color: '#ccc' }}>You can delete sessions you don't want to see.</p>
        </div>
        <div style={additionalImageContainer}>
          <img src={"images/delete_archive-min.jpg"} alt={"gif"} style={{ width: "100%", display: "flex" }} className="content-gif" />
        </div>
      </div>

      <div style={tasksSectionStyle}>
        <div style={tasksTextContainer}>
          <h2 style={{ fontSize: '2rem', marginBottom: '1rem' }}>Change</h2>
          <p style={{ fontSize: '1.2rem', marginBottom: '1rem', color: '#ccc' }}>You can modify fields that were entered incorrectly</p>
        </div>
        <div style={tasksImageContainer}>
          <img src={"images/change_archive-min.jpg"} alt={"gif"} style={{ width: "100%", display: "flex" }} className="content-gif" />
        </div>
      </div>

      <div style={centeredSectionStyle}>
        <Stack direction="row" spacing={1} sx={{ flexDirection: "row", justifyContent: "center", marginTop: 5, marginBottom: 2 }}>
          <Chip label="For Personal Space" id="For Riders" component="a" href="#For Riders" variant="outlined" clickable sx={{ color: 'white' }} />
          <Chip label="For Racing Teams" id="For Team Managers" component="a" href='#For Team Managers' variant="outlined" color='primary' clickable />
        </Stack>
        <Typography
          variant="h4"
          component="div"
          gutterBottom
          sx={{
            fontSize: '2rem',
            fontWeight: 'bold',
            marginTop: '40px',
          }}
        >
          Teams go faster with AiMotor
        </Typography>
        <Typography
          variant="body1"
          component="div"
          gutterBottom
          sx={{
            fontSize: '1rem',
            marginBottom: '20px',
          }}
        >
          As a team member, you will have access not only to your data but also to all track data entered by your teammates
        </Typography>
      </div>



      <Grid container justifyContent="center">
        <Grid item md={10}>
          <ImageTextCard
            imageUrl={'images/team_arch.jpg'}
            imageAlt="By knowing all the team's setups in real-time, you can understand the changes needed for your Go-Kart and enhance your performance"
            description="By knowing all the team's setups in real-time, you can understand the changes needed for your Go-Kart and enhance your performance"
            sx={{
              textAlign: 'left',
              height: { lg: '280px' },
            }}
          />
        </Grid>
      </Grid>

      <div style={gridStyle}>
        {features.map((feature, index) => (
          <div key={index} style={cardStyle}>
            <div style={iconStyle}>{feature.icon}</div>
            <h3 style={titleStyle}>{feature.title}</h3>
            <p style={descriptionStyle}>{feature.description}</p>
          </div>
        ))}
      </div>

      <BackgroundContainer style={{ width: '100%', textAlign: 'center' }}>
        <Typography variant="h4" sx={{ fontWeight: 'bold', mb: 2 }}>
          Go Faster today
        </Typography>
        <Link to="/sign-up" className="insertdata">
          <StyledButton variant="contained">
            Register
          </StyledButton>
        </Link>
        
      </BackgroundContainer>
      <div style={containerStyle1}>
      <Footer />
      </div>
    </div>
  );
};




export default WhyTara;
