import PayPalProvider from './PaypalProvider'

const CLIENT_ID = process.env.REACT_APP_PAYPAL_CLIENT_ID;
const SECRET = process.env.REACT_APP_PAYPAL_SECRET;

export const getToken = async () => {
  try {
    const response = await PayPalProvider.post('oauth2/token', 'grant_type=client_credentials',
      {
        auth: {
          username: CLIENT_ID,
          password: SECRET
        },
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }
    );
    return response.data.access_token;
  } catch (error) {
    console.error('Error getting token:', error);
    return ''
  }
}

export const preparePlanOptions = (planType, { period , price, currency, isTrial }) => {
  

    if (isTrial) {
      return [
        {
          frequency: {
            interval_unit: 'Month',
            interval_count: 1
          },
          tenure_type: 'TRIAL',
          sequence: 1,
          total_cycles: 1,
          pricing_scheme: {
            fixed_price: {
              value: 0,
              currency_code: currency
            }
          }
        },
        {
          frequency: {
            interval_unit: period,
            interval_count: 1
          },
          tenure_type: 'REGULAR',
          sequence: 2,
          total_cycles: 0,
          pricing_scheme: {
            fixed_price: {
              value: price,
              currency_code: currency
            }
          }
        }
      ];
    } else {
      return [
        {
          frequency: {
            interval_unit: period,
            interval_count: 1
          },
          tenure_type: "REGULAR",
          sequence: 1,
          total_cycles: 0,
          pricing_scheme: {
            fixed_price: {
              value: price,
              currency_code: currency
            }
          }
        }
      ]

}
}

export const pricingOptions = [
  {
    title: 'Growth',
    price: '$25/month',
    yearPrice: '$280/month',
    priceNumber: 25,
    features: [
      '1 user',
      '2 Active Projects',
    ],
    buttonColor: '#00CFFD',
    titleColor: '#000',
  },
  {
    title: 'Pro',
    price: '$55/month',
    yearPrice: '$640/year',
    priceNumber: 55,
    features: [
      '1 user',
      'Unlimited Projects',
      'Download Prototypes',
      'Remove Doov Branding',
      'Password Protection'
    ],
    buttonColor: '#00CFFD',
    titleColor: '#000',
  },
  {
    title: 'Team',
    price: '$70/month',
    yearPrice: '$820/year',
    priceNumber: 70,
    features: [
      'Unlimited Users',
      'Unlimited Projects',
      'Download Prototypes',
      'User roles and groups',
      'Password Protection'
    ],
    buttonColor: '#00CFFD',
    titleColor: '#000',
  },
];

export const getPlanById = (planTitle) => {
  if (planTitle) {
    const find = pricingOptions.find(opt => opt.title === planTitle)
    return find
  } else {
    return undefined
  }
}