import { Box, Chip } from '@mui/material';
import React, { useEffect, useState } from 'react';

import HttpProvider from 'services/HttpProvider'
import StepperContentLoader from './Loaders';
import { observer } from "mobx-react-lite";

const SelectRace = ({
  selectedTeam = {},
  activeRace = '',
  onChange = () => {},
  isTry,
  ...props
  
}) => {
  const competitions1 = [
    {
        "id": 4,
        "competition": "AiMotor Race",
        "track": "Lonato"
    },
]
  const [competitions, setCompetitions] = useState(isTry ? competitions1 : []);
  const [isLoading, setIsLoading] = useState(false);
  const [race, setRace] = useState(activeRace);
  

  const fetchCompetitions = async () => {
    setIsLoading(true);
    return HttpProvider.get(`/get_competitions/?team_id=${selectedTeam.id}`)
      .then((res) => {
        setCompetitions(res.data);
      })
      .catch((err) => {
        console.error('Error fetching competitions:', err);
      })
      .finally(() => {
        setIsLoading(false);
      })
  };

  const handleRaceClick = (id) => {
    setRace(id)
    onChange(id)
  }

 useEffect(() => {
  if(!isTry){
     fetchCompetitions();
  }
   
  }, [selectedTeam]);

  return (
    <Box textAlign="center" {...props}>
      {
        isLoading ? <StepperContentLoader />
        : 
        <Box flexWrap={"wrap"}>
          {
          competitions.map((c) => (
            <Chip
              label={`${c.competition} (${c.track})`}
              key={c.id}
              color={c.id === race ? 'primary': 'default'}
              onClick={() => handleRaceClick(c.id)} sx={{ mb: 1.5, mr: 2 }}
            />
          ))
          }
        </Box>
      }
    </Box>
  );
};

export default observer(SelectRace);