import {
  Avatar,
  Box,
  Button,
  Divider,
  IconButton,
  LinearProgress,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Fragment, useCallback, useEffect, useMemo, useState } from "react";

import AttachFileIcon from "@mui/icons-material/AttachFile";
import DeleteIcon from "@mui/icons-material/Delete";
import CardLoader from "components/Loaders/CardLoader";
import HttpProvider from "services/HttpProvider";
import remove from "lodash/remove";

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: 50, mr: 1 }} hidden={props.status !== "processing"}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" sx={{ color: "text.secondary" }}>
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}

let times = 30;

export default function FilesRecap({
  refreshList = false,
  useLinkToPage = false,
  length = 5,
  onComplete = () => {},
  sx = {},
  ...props
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [processing, setProcessing] = useState([]);
  const [files, setFiles] = useState([]);
  const [selectedFileId, setSelectedFileId] = useState(null); // Track selected file

  const loadFiles = ({ useLoader = true }) => {
    setIsLoading(useLoader);
    return HttpProvider.get(`/files_uploaded`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        accept: "application/json",
      },
    })
      .then((res) => {
        const { data = [] } = res;
        const processingArray = remove(data, (d) => d.status === "processing");
        setProcessing(processingArray);
        setFiles(data);
        return data;
      })
      .catch((error) => {
        console.error("Axios Error:", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleFileClick = (fileId) => {
    setSelectedFileId((prevSelectedId) => (prevSelectedId === fileId ? null : fileId));
  };

  const handleDeleteClick = (file) => {
    setIsLoading(true); // Show loader during deletion process
    return HttpProvider.delete(`/delete_session_by_id`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        accept: "application/json",
      },
      params: {
        upload_id: file._id, // Pass the file's unique ID as a query parameter
      },
    })
      .then((res) => {
        console.log("File deleted successfully:", res);
        // Remove the file from the local state after successful deletion
        setFiles((prevFiles) => prevFiles.filter((f) => f._id !== file._id));
      })
      .catch((error) => {
        console.error("Error deleting file:", error);
      })
      .finally(() => {
        toast.success("File deleted", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setIsLoading(false); // Hide loader once deletion is complete
      });
  };

  const ListTile = useCallback(({ file }) => {
    const isSelected = selectedFileId === file._id;

    return (
      <>
        <ListItem
          onClick={() => handleFileClick(file._id)}
          sx={{
            backgroundColor: isSelected ? "rgba(0, 0, 255, 0.05)" : "inherit", // Lighter blue

            border: isSelected ? "1px solid blue" : "none",
            borderRadius: "8px",
            transition: "all 0.3s ease",
            cursor: "pointer",
          }}
        >
          <ListItemAvatar>
            <Avatar>
              <AttachFileIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={file.track}
            secondary={`race: ${file.race} | laps: ${file.laps}`}
          />
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <IconButton
              onClick={(e) => {
                e.stopPropagation(); // Prevent triggering file selection
                handleDeleteClick(file);
              }}
              sx={{
                color: "red",
                visibility: isSelected ? "visible" : "hidden",
                transition: "visibility 0.2s ease",
              }}
            >
              <DeleteIcon />
            </IconButton>
            <LinearProgressWithLabel value={file.percentage} status={file.status} />
          </Box>
        </ListItem>
        <Divider />
      </>
    );
  }, [selectedFileId]);

  const isEmptyList = useMemo(() => {
    return files.length === 0 && processing.length === 0;
  }, [files, processing]);

  useEffect(() => {
    let progress;
    if (processing.length && times > 0) {
      progress = setInterval(() => {
        loadFiles({ useLoader: false }).finally(() => {
          times--;
        });
      }, 2000);
    } else {
      clearInterval(progress);
      onComplete();
    }

    return () => {
      clearInterval(progress);
      onComplete();
    };
  }, [processing.length]);

  useEffect(() => {
    loadFiles({ useLoader: true });
  }, [, refreshList]);

  return (
    <Box {...props}>
      <Stack direction={"row"} justifyContent="space-between" alignItems={"center"}>
        <Typography variant="h4" component="div">
          Your Files
        </Typography>
        {useLinkToPage ? (
          // todo add link to page
          <Button variant="contained" color="primary">
            Go to the list
          </Button>
        ) : null}
      </Stack>
      <Box mt={3}>
        {isLoading ? (
          <CardLoader height={70} times={10} spacing={0.5} />
        ) : isEmptyList ? (
          <Typography variant="body1">No file in the list</Typography>
        ) : (
          <List>
            {processing.map((file) => (
              <Fragment key={file._id}>
                <ListTile file={file} />
              </Fragment>
            ))}
            {files.map((file) => (
              <Fragment key={file._id}>
                <ListTile file={file} />
              </Fragment>
            ))}
          </List>
        )}
      </Box>
    </Box>
  );
}
