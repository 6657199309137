import ImageTextCard from 'components/Card/ImageText';
import React from 'react';
import { Box, Typography, Button, Grid, Stack,Chip } from '@mui/material';
import { styled } from '@mui/system';
import { Link } from "react-router-dom";
import CallToAction from "./CallToAction.js"; // Ensure CallToAction.js is properly imported and used
import DriverAnalyticsStepper from "../DriverAnalyticsStepper.js";
import { useEffect, useRef, useState } from 'react';
import Footer from "../Footer";
const WhyTara = () => {
  const features = [
    // Features can be added here if needed
  ];

  const BackgroundContainer = styled(Box)(({ theme }) => ({
    background: 'linear-gradient(135deg, #3A49F9 0%, #4D5DFB 100%)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '30vh',
    color: '#fff',
    textAlign: 'center',
    flexDirection: 'column',
    position: 'relative',
    width: '100%', // Make it occupy full width
    margin: 0, // Remove any margin
    padding: 0, // Remove any padding
  }));
  
  const StyledButton = styled(Button)(({ theme }) => ({
    backgroundColor: '#fff',
    color: '#3A49F9',
    borderRadius: '25px',
    padding: '10px 20px',
    textTransform: 'none',
    fontWeight: 'bold',
    fontSize: '1rem',
    '&:hover': {
      backgroundColor: '#e0e0e0',
    },
  }));

  const containerStyle = {


    textAlign: 'center',
    backgroundColor: '#000',
    color: '#fff',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%', // Ensure full width
  };

  const containerStyle1 = {


    textAlign: 'center',
    backgroundColor: '#000',
    color: '#fff',
    boxSizing: 'border-box',

    flexDirection: 'column',
    alignItems: 'center',
    width: '100%', // Ensure full width
  };

  const heroStyle = {
    textAlign: 'left',
    background: '#3A49F9',
    padding: '4rem 2rem',
    borderRadius: '10px',
    boxShadow: '0 2px 4px rgba(255, 255, 255, 0.1)',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    color: '#fff',
    flexWrap: 'wrap',
    width: '100%',
    maxWidth: '1500px' // Ensures the hero section doesn't stretch too wide
  };

  const heroTextContainer = {
    flex: '1',
    paddingRight: '2rem',
    minWidth: '300px',
    boxSizing: 'border-box'
  };

  const heroImageContainer = {
    flex: '1',
    textAlign: 'center',
    minWidth: '300px',
    boxSizing: 'border-box'
  };

  const heroImageStyle = {
    maxWidth: '100%',
    borderRadius: '10px',
    boxShadow: '0 2px 4px rgba(255, 255, 255, 0.1)'
  };

  const headlineStyle = {
    fontSize: '1.5rem',
    marginBottom: '1rem'
  };

  const subheadlineStyle = {
    fontSize: '1.2rem',
    marginBottom: '1.5rem',
    color: '#ccc'
  };

  const centeredSectionStyle = {
    textAlign: 'center',
    marginTop: '4rem',
    marginBottom: '4rem'
  };

  const additionalSectionStyle = {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    textAlign: 'left',
    flexWrap: 'wrap',
    width: '100%',
    maxWidth: '1200px' // Ensures the additional section doesn't stretch too wide
  };

  const additionalTextContainer = {
    flex: '1',
    paddingRight: '2rem',
    minWidth: '300px',
    boxSizing: 'border-box'
  };

  const additionalImageContainer = {
    flex: '1',
    textAlign: 'center',
    minWidth: '300px',
    boxSizing: 'border-box'
  };

  const additionalImageStyle = {
    maxWidth: '100%',
    borderRadius: '10px',
    boxShadow: '0 2px 4px rgba(255, 255, 255, 0.1)'
  };

  const tasksSectionStyle = {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: '4rem',
    textAlign: 'left',
    flexWrap: 'wrap',
    width: '100%',
    maxWidth: '1200px' // Ensures the tasks section doesn't stretch too wide
  };

  const tasksTextContainer = {
    flex: '1',
    paddingRight: '2rem',
    minWidth: '300px',
    boxSizing: 'border-box'
  };

  const tasksImageContainer = {
    flex: '1',
    textAlign: 'center',
    minWidth: '300px',
    boxSizing: 'border-box'
  };

  const tasksImageStyle = {
    maxWidth: '100%',
    borderRadius: '10px',
    boxShadow: '0 2px 4px rgba(255, 255, 255, 0.1)'
  };

  const gridStyle = {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
    gap: '2rem',
    marginTop: '2rem',
    width: '100%',
    maxWidth: '1200px' // Ensures the grid doesn't stretch too wide
  };

  const cardStyle = {
    background: '#444',
    padding: '1.5rem',
    borderRadius: '10px',
    boxShadow: '0 2px 4px rgba(255, 255, 255, 0.1)',
    transition: 'transform 0.3s ease',
    color: '#fff'
  };

  const iconStyle = {
    fontSize: '2rem',
    marginBottom: '1rem'
  };

  const titleStyle = {
    fontSize: '1.5rem',
    marginBottom: '1rem'
  };

  const descriptionStyle = {
    fontSize: '1rem',
    color: '#ccc'
  };

  const headingStyle = {
    marginTop: '4rem',
    fontSize: '2rem',
    color: '#fff'
  };

  const NewComponent = () => {
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
      const handleResize = () => {
        setIsMobile(window.innerWidth <= 768); // Adjust breakpoint as needed
      };
  
      window.addEventListener('resize', handleResize);
      handleResize();
  
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);

    const newComponentStyles = {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      backgroundColor: '#333',
      borderRadius: '10px',
      overflow: 'hidden',
      marginBottom: '20px',
      width: '80%',
      maxWidth: '1200px',
      height: 'auto',
      margin: '20px 0',
    };

    const gifContainerStyles = {
      width: '50%',
      height: 'auto',
      display: 'flex',
    };

    const gifStyles = {
      width: '100%',
      height: 'auto',
      display: 'block',
    };

    const textContainerStyles = {
      width: '50%',
      color: 'white',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      padding: '20px',
    };

    const textMessageStyles = {
      fontSize: '30px',
      marginBottom: '10px',
    };

    const textDescriptionStyles = {
      fontSize: '16px',
    };
    const listContainerStyles = {
      width: '50%',
      color: 'white',
      padding: '20px',
      textAlign: 'left', // Ensures text alignment
    };

      const items = !isMobile ? (
        [
      <div style={newComponentStyles}>
        <div style={gifContainerStyles}>
          <img src={"videos/team_analytics.gif"} alt="GIF" style={gifStyles} />
        </div>
        <div style={listContainerStyles}>
          <div style={textMessageStyles}>Barchart</div>
          <div style={textDescriptionStyles}>With more than one driver, each bar chart is divided into three sections: the first represents the minimum value entered by one of the drivers, the middle shows the average value, and the upper displays the maximum value</div>
        </div>
      </div>
        ]
      ) : null;
    
      return items;
  };

  const NewComponent2 = () => {
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
      const handleResize = () => {
        setIsMobile(window.innerWidth <= 768); // Adjust breakpoint as needed
      };
  
      window.addEventListener('resize', handleResize);
      handleResize();
  
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);


    const newComponentStyles = {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      backgroundColor: '#333',
      borderRadius: '10px',
      overflow: 'hidden',
      marginBottom: '30px',
      width: '80%',
      maxWidth: '1200px',
      height: 'auto',
      marginTop: '30px',
    };

    const textMessageStyles = {
      fontSize: '30px',
      marginBottom: '10px',
    };

    const gifContainerStyles = {
      width: '50%',
      height: 'auto',
      display: 'flex',
    };

    const gifStyles = {
      width: '100%',
      height: 'auto',
      display: 'block',
    };

    const textContainerStyles = {
      width: '50%',
      color: 'white',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      padding: '20px',
    };

    const textDescriptionStyles = {
      fontSize: '16px',
    };

    const listContainerStyles = {
      width: '50%',
      color: 'white',
      padding: '20px',
      textAlign: 'left', // Ensures text alignment
    };

    const items = !isMobile ? (
      [
        <div style={newComponentStyles} key="item1">
          <div style={gifContainerStyles}>
            <img src={"videos/video_2_driver.gif"} alt="GIF" style={gifStyles} />
          </div>
          <div style={listContainerStyles}>
            <div style={textMessageStyles}>Table</div>
            <div style={textDescriptionStyles}>With more than one driver, the values entered by the selected drivers for each field are compared</div>
          </div>
        </div>
      ]
    ) : null;
    
    return items;
    
    
  };

  const NewComponentStyle = {
    display: 'block',
    '@media (max-width: 768px)': {
      display: 'none',
    },
  };
  
  const NewComponent2Style = {
    display: 'block',
    '@media (max-width: 768px)': {
      display: 'none',
    },
  };

  return (
    <div style={containerStyle}>
      <div style={heroStyle}>
        <div style={heroTextContainer}>
          <h1 style={headlineStyle}>Installing software can be time-consuming: Analyze your telemetry data directly from your mobile phone</h1>
          <p style={subheadlineStyle}>Driver Analytics makes driving analysis easier than ever before</p>
        </div>
        <div style={heroImageContainer}>
          <img src="images/picture_4_driver.jpg" alt="Software Screenshot" style={heroImageStyle} />
        </div>
      </div>
      <div style={centeredSectionStyle}>
      <Stack direction="row" spacing={1} justifyContent={'center'}>
    <Chip label="For Personal Space" id="For Riders" component="a" href="#For Riders" variant="outlined" color='primary' clickable /*sx={{color: 'white' }}*/ />
    <Chip label="For Racing Teams" component="a" href='#For Team Managers' variant="outlined" clickable sx={{ color: 'white' }} />
</Stack>
      </div>
      <DriverAnalyticsStepper/>
      <Grid item md={10} marginTop={10} maxWidth={1500}>
          <ImageTextCard
            imageUrl={"videos/video_2_driver.gif"}
            imageAlt='Each point on the two graphs is connected to its respective position on the track. The interface is draggable and modular'
            title={
              <Typography
                component="span"
                sx={{
                  color: '#5865f2',
                  fontSize: '2.5rem',
                  fontWeight: 'bold'
                }}
              >
               Track Map
              </Typography>
            }
            description="Each point on the two graphs is connected to its respective position on the track. The interface is draggable and modular"
            sx={{
              textAlign: 'left',
              height: { lg: '280px'},
            }}
          />
        </Grid>
        <Grid item md={10} marginTop={10} maxWidth={1500}>
          <ImageTextCard
            imageUrl={"images/picture_2_driver.jpg"}
            imageAlt='For each lap you analyze, the Setup section displays the configuration you used.'
            title={
              <Typography
                component="span"
                sx={{
                  color: '#5865f2',
                  fontSize: '2.5rem',
                  fontWeight: 'bold'
                }}
              >
             Setup
              </Typography>
            }
            description="For each lap you analyze, the Setup section displays the configuration you used."
            sx={{
              textAlign: 'left',
              height: { lg: '280px'},
            }}
          />
        </Grid>
      <Stack direction="row" spacing={1} sx={{ flexDirection: "row", justifyContent: "center", marginTop: 10, marginBottom: 2 }}>
                    <Chip label="For Personal Space" id="For Riders" component="a" href="#For Riders" variant="outlined" clickable sx={{ color: 'white' }} />
                    <Chip label="For Racing Teams" id="For Team Managers" component="a" href='#For Team Managers' variant="outlined" color='primary' clickable />
                </Stack>
      <Typography
        variant="h4"
        component="div"
        gutterBottom
        sx={{
          fontSize: '2rem',
          fontWeight: 'bold',
          marginTop: '40px',
        }}
      >
        Teams go faster with AiMotor
      </Typography>
      <Typography
        variant="body1"
        component="div"
        gutterBottom
        sx={{
          fontSize: '1rem',
          marginBottom: '20px',
        }}
      >
By selecting multiple drivers, Analytics allows you to compare your telemetry data with your teammates
      </Typography>
      
      

      <div style={gridStyle}>
        {features.map((feature, index) => (
          <div key={index} style={cardStyle}>
            <div style={iconStyle}>{feature.icon}</div>
            <h3 style={titleStyle}>{feature.title}</h3>
            <p style={descriptionStyle}>{feature.description}</p>
          </div>
        ))}
      </div>

      <BackgroundContainer>
        <Typography variant="h4" sx={{ fontWeight: 'bold', mb: 2 }}>
          Go Faster today
        </Typography>
        <Link to="/sign-up" className="insertdata">
          <StyledButton variant="contained">
            Register
          </StyledButton>
        </Link>
      </BackgroundContainer>
      <div style={containerStyle1}>
      <Footer />
      </div>
   
    </div>
  );
};

export default WhyTara;

