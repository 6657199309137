import React, { useState, useEffect, useRef } from 'react';
import { LineChart } from '@mui/x-charts/LineChart';
import Draggable from 'react-draggable';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import { Box, IconButton, Chip, Tooltip, Typography, Paper } from '@mui/material';
import axios from 'axios';
import CloseIcon from '@mui/icons-material/Close';
import changeSector1 from '../TryFiles/change_sector.json';
import latLong from '../TryFiles/filtered_long_lat.json';

export default function MarkOptimization({ track, hoverLatitude, hoverLongitude, trackIndex, race, driverSessions, day }) {
    const [chartSize, setChartSize] = useState({ width: window.innerWidth < 500 ? 350 : 500, height: window.innerWidth < 500 ? 210 : 300 });
    const [isHovered, setIsHovered] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [dataset, setDataset] = useState([]);
    const [sectors, setSectors] = useState([]);
    console.log("change sector 1", changeSector1)
    const [showSectors, setShowSectors] = useState(true);
    const [showSectorNumbers, setShowSectorNumbers] = useState(false);
    const [ballColors, setBallColors] = useState([]);
    const [sectorParts, setSectorParts] = useState([]);  // Initialization added here
    const chartRef = useRef(null);
    const containerRef = useRef(null);
    const [shouldGetSectors, setShouldGetSectors] = useState(false);  
    console.log("try data track", dataset)
    console.log("try data sectors", sectorParts)
    let paddingBlock = 49.2;
    let paddingInline = 49.2;
    console.log("padding", chartSize)



    const filterDataByDriverSessions = (data, driverSessions) => {
        // Extract the emails and laps that appear in driverSessions
        const driverEmails = driverSessions.map(session => session.email);
        const driverLaps = driverSessions.reduce((acc, session) => {
            acc[session.email] = session.sessions.flatMap(s => s.laps); // Flatten the laps for each session
            return acc;
        }, {});

        // Filter data to only include entries where the email and lap match those in driverSessions
        return data.filter(item =>
            driverEmails.includes(item.email) &&
            driverLaps[item.email].includes(item.lap)

        );
    };






    const getSectors = () => {
         // Debugging line
        const changeSector = filterDataByDriverSessions(changeSector1, driverSessions);
        const sectorTimes = changeSector[0]?.sectorData?.sector_times || [];
        const roundedSectorParts = sectorTimes.map(time => Math.round(time / 0.16));
        setSectorParts(roundedSectorParts);  // Update sector parts state
        setShowSectors(prevShowSectors => !prevShowSectors);  // Revert showSectors to its previous value
        setDataset(filterDataByDriverSessions(latLong, driverSessions));  // Update the dataset
    };

    const getTrackData = () => {
        setShowSectors(prevShowSectors => !prevShowSectors);  // Toggle showSectors state
        setShouldGetSectors(true);
        setIsLoading(true)  // Set flag to indicate getSectors should be called
    };

    useEffect(() => {
        getTrackData();  // Call getTrackData on mount or when dependencies change
    }, [chartSize, driverSessions, track]);  // Dependencies for useEffect

    useEffect(() => {
        if (shouldGetSectors) {
            getSectors();  // Call getSectors if the flag is set
            setShouldGetSectors(false);
            setIsLoading(false)    // Reset flag to avoid infinite loop
        }
    }, [showSectors, shouldGetSectors]); 


    useEffect(() => {
        const generateRandomColor = () => {
          const letters = '0123456789ABCDEF';
          let color = '#';
          for (let i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
          }
          return color;
        };
    
        const colors = dataset.map(() => generateRandomColor());
        setBallColors(colors);
      }, [dataset]);
    
      const increaseSize = () => {
        if (chartSize.width < window.innerWidth) {
          setChartSize((prevSize) => ({
            width: prevSize.width * 1.1,
            height: prevSize.height * 1.1,
          }));
          paddingBlock = paddingBlock * 1.1;
          paddingInline = paddingInline * 1.1;
        }
      };
    
      const decreaseSize = () => {
        setChartSize((prevSize) => ({
          width: Math.max(prevSize.width * 0.9, 100),
          height: Math.max(prevSize.height * 0.9, 100),
        }));
      };
    
      const handleTouchOrClickOutside = (event) => {
        if (chartRef.current && !chartRef.current.contains(event.target)) {
          setIsHovered(false);
        }
      };
    
      useEffect(() => {
        document.addEventListener('touchstart', handleTouchOrClickOutside, { passive: true });
        document.addEventListener('mousedown', handleTouchOrClickOutside);
    
        return () => {
          document.removeEventListener('touchstart', handleTouchOrClickOutside);
          document.removeEventListener('mousedown', handleTouchOrClickOutside);
        };
      }, []);
    
      const calculateTopPosition = (latitude) => {
        const minLatitude = Math.min(...latitudeData);
        const maxLatitude = Math.max(...latitudeData);
        const mapHeight = chartSize.height - paddingBlock * 2;
        return ((maxLatitude - latitude) * mapHeight) / (maxLatitude - minLatitude);
      };
    
      const calculateLeftPosition = (longitude) => {
        const minLongitude = Math.min(...longitudeData);
        const maxLongitude = Math.max(...longitudeData);
        const mapWidth = chartSize.width - paddingBlock * 2;
        return ((longitude - minLongitude) / (maxLongitude - minLongitude)) * mapWidth;
      };
    
      const longitudeData = dataset[0]?.drivingData?.map((point) => point.longitude) || [];
      const latitudeData = dataset[0]?.drivingData?.map((point) => point.latitude) || [];
    
      const marginTopValue = chartRef.current ? -chartRef.current.clientHeight : -chartSize.height;
    
      const minLongitude = Math.min(...longitudeData);
      const maxLongitude = Math.max(...longitudeData);
      const minLatitude = Math.min(...latitudeData);
      const maxLatitude = Math.max(...latitudeData);
    
      const series = showSectors ? sectorParts?.map((start, index) => {
        const end = sectorParts[index + 1] !== undefined ? sectorParts[index + 1] : latitudeData?.length;
        if (start >= end || start < 0 || end > latitudeData.length) {
          return null;
        }
        return {
          data: [
            ...(index !== 0 ? Array(start).fill(null) : []),
            ...latitudeData.slice(start, end + 1)
          ],
          showMark: false,
          lineStyle: { stroke: 'transparent' },
          id: `sector-${index}`,
        };
      }).filter(Boolean) : [{
        data: latitudeData,
        showMark: false,
        lineStyle: { stroke: 'transparent' },
        id: 'track'
      }];
    
      return (
        <>
        
          <Draggable >
            <Box
              sx={{ position: 'relative', display: 'inline-block' }}
              onMouseEnter={() => setIsHovered(true)}
              onTouchStart={() => setIsHovered(true)}
              ref={chartRef}
            >
              {isHovered && (
                <>
                  <Chip
                    label="Show Sectors"
                    color={showSectors ? 'primary' : 'default'}
                    onClick={() => setShowSectors(!showSectors)}
                    onTouchStart={() => setShowSectors(!showSectors)}
                    sx={{ position: 'absolute', top: 10, left: 10, zIndex: 12 }}
                  />
                  {showSectors && (
                    <Chip
                      label="Sector Numbers"
                      color={showSectorNumbers ? 'secondary' : 'default'}
                      onClick={() => setShowSectorNumbers(!showSectorNumbers)}
                      onTouchStart={() => setShowSectorNumbers(!showSectorNumbers)}
                      sx={{ position: 'absolute', top: 10, left: 130, zIndex: 12, fontSize: '12px' }}
                    />
                  )}
                  {/* Legend Box */}
                  <Paper
                    sx={{
                      position: 'absolute',
                      top:  chartSize.width > window.innerWidth* 0.8 ? chartSize.height * 0.45 : chartSize.height * 0.8,
                      right: chartSize.width > window.innerWidth* 0.8 ? -chartSize.width / 3 : +chartSize.width / 3,
                      zIndex: 12,
                      padding: '10px',
                      backgroundColor: 'white',
                      borderRadius: '4px',
                      boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
                    }}
                  >
                    {dataset.map((data, index) => (
                      <Box key={index} sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                        <Box
                          sx={{
                            width: 20,
                            height: 20,
                            backgroundColor: ballColors[index],
                            borderRadius: '50%',
                            marginRight: '8px',
                          }}
                        />
                        {/* Update to use surname instead of email */}
                        <Typography variant="body2">{data.surname || 'Unknown'}</Typography>
                        <Typography variant="body2" sx={{ marginLeft: 'auto' }}>Lap: {data.lap || 'N/A'}</Typography>
                      </Box>
                    ))}
                  </Paper>
                </>
              )}
              {!isLoading && dataset[0]?.drivingData?.length > 0 ? (
                <>
                  <LineChart
                    axisHighlight={false}
                    grid={false}
                    xAxis={[{
                      data: longitudeData,
                      min: minLongitude,
                      max: maxLongitude,
                      hide: true,
                      disableLine: true,
                      disableTicks: true,
                      hideTooltip: true,
                      tickInterval: []
                    }]}
                    yAxis={[{
                      min: minLatitude,
                      max: maxLatitude,
                      hide: true,
                      disableLine: true,
                      disableTicks: true,
                      hideTooltip: true,
                      tickInterval: []
                    }]}
                    series={series}
                    width={chartSize.width} // Use updated state values
                    height={chartSize.height} // Use updated state values
                    highlightedItem={{
                      seriesId: 'track',
                      dataIndex: 10,
                    }}
                    interactions={{ hover: true, tooltip: { enabled: true } }}
                  />
                  {dataset.map((data, index) => {
                    const currentLatitude = data?.drivingData?.[Math.round(trackIndex/2)]?.latitude;
                    const currentLongitude = data?.drivingData?.[Math.round(trackIndex/2)]?.longitude;
                    const ballColor = ballColors[index];
    
                    return (
                      currentLatitude && currentLongitude && (
                        <div
                          key={index}
                          ref={containerRef}
                          style={{
                            position: 'absolute',
                            height: '100%',
                            width: '100%',
                            marginTop: `${marginTopValue}px`,
                            paddingBlock: `${paddingBlock}px`,
                            paddingInline: `${paddingInline}px`,
                          }}
                        >
                          <div
                            style={{
                              position: 'relative',
                              top: `${calculateTopPosition(currentLatitude)}px`,
                              left: `${calculateLeftPosition(currentLongitude)}px`,
                              transform: 'translate(-50%, -50%)',
                              backgroundColor: ballColor,
                              borderRadius: '50%',
                              width: '10px',
                              height: '10px',
                              zIndex: 11,
                            }}
                          />
                        </div>
                      )
                    );
                  })}
    
                  {showSectors && sectorParts?.map((point, index) => (
                    <div
                      key={index}
                      ref={containerRef}
                      style={{
                        position: 'absolute',
                        height: '100%',
                        width: '100%',
                        marginTop: `${marginTopValue}px`,
                        paddingBlock: `${paddingBlock}px`,
                        paddingInline: `${paddingInline}px`,
                      }}
                    >
                       {showSectorNumbers ? (
                        // Render the sector numbers instead of X markers
                        <Typography
                          variant="caption"
                          style={{
                            position: 'relative',
                            top: `${calculateTopPosition(dataset[0]?.drivingData[point]?.latitude)-8}px`,
                            left: `${calculateLeftPosition(dataset[0]?.drivingData[point]?.longitude)-3}px`,
                            transform: 'translate(-50%, -50%)',
                            zIndex: 12,
                            color: 'black',
                            fontSize: window.innerWidth < 400 ? '12px' : '14px',// Adjust font size as needed
                            padding: '2px',
                            borderRadius: '4px',
                            // Optional: Add background color for better visibility
                          }}
                        >
                          {index == 0 ? sectorParts?.length-1 : index-1} {/* Display the sector number */}
                        </Typography>
                      ) : (
                        // Render the original X markers when sector numbers are not selected
                        <Tooltip title={`Sector ${index + 1}`}>
                          <CloseIcon
                            style={{
                              position: 'relative',
                              top: `${calculateTopPosition(dataset[0]?.drivingData[point]?.latitude)}px`,
                              left: `${calculateLeftPosition(dataset[0]?.drivingData[point]?.longitude)}px`,
                              transform: 'translate(-50%, -50%)',
                              color: 'black',
                              fontSize: '20px',
                              zIndex: 10,
                            }}
                          />
                        </Tooltip>
                      )}
                    </div>
                  ))}
                </>
              ) : (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: chartSize.width,
                    height: chartSize.height,
                    backgroundColor: '#f0f0f0',
                    borderRadius: '8px',
                  }}
                >
                  {isLoading ? 'Loading data...' : (!race ? 'Select Race' : (driverSessions ? 'Select a lap' : 'Select a Driver'))}
    
                </Box>
              )}
              {isHovered && (
                <Box
                  sx={{
                    position: 'absolute',
                    top: 10,
                    right: 10,
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 1,
                    backgroundColor: 'rgba(255, 255, 255, 0.8)',
                    borderRadius: '4px',
                    padding: '4px',
                  }}
                >
                  {chartSize.width < window.innerWidth && (<IconButton onClick={increaseSize} size="small" sx={{ zIndex: 300 }}
                    onTouchStart={increaseSize}
                  >
                    <ZoomInIcon />
                  </IconButton>)}
                  <IconButton onClick={decreaseSize} size="small" sx={{ zIndex: 300 }}
                    onTouchStart={decreaseSize}
                  >
                    <ZoomOutIcon />
                  </IconButton>
                </Box>
              )}
            </Box>
          </Draggable>
        </>
      );
    }
    