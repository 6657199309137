import React, { useState, useEffect } from 'react';
import { Box, Button, Grid, Stack, Typography } from '@mui/material';

import BuildIcon from '@mui/icons-material/Build';
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing'; // Caster/Mechanical Setup
import SwapHorizIcon from '@mui/icons-material/SwapHoriz'; // Axle Position
import SpeedIcon from '@mui/icons-material/Speed'; // Fast Driver & Setup
import CompareIcon from '@mui/icons-material/Compare'; // Time Comparison
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar'; // Exit Speeds
import TrackChangesIcon from '@mui/icons-material/TrackChanges'; // Braking Points
import ElectricBoltIcon from '@mui/icons-material/ElectricBolt'; // Max RPM with Sprocket and Pinion
import TuneIcon from '@mui/icons-material/Tune'; // Setup for Fastest Sector
import TireRepairIcon from '@mui/icons-material/TireRepair'; // Rear Grip & Tires

const suggestionGroups = [
  [
    {
      icon: <PrecisionManufacturingIcon />,
      text: 'Fastest teammate caster',
      detailedMessage: "What caster did my fastest teammate use in the first setup session yesterday?",
    },
    {
      icon: <SwapHorizIcon />,
      text: 'Axle position change',
      detailedMessage: "Should I change the axle position for the next session?",
    },
    {
      icon: <SpeedIcon />,
      text: 'Fastest driver setups',
      detailedMessage: "Who was the fastest driver on my team yesterday, and what setups did they use?",
      hideMobile: true
    },
    {
      icon: <CompareIcon />,
      text: 'Sector 3 time comparison',
      detailedMessage: "Why am I slower than my teammate in Sector 3?",
      hideMobile: true
    }
  ],
  [
    {
      icon: <DirectionsCarIcon />,
      text: 'Sector 5 exit speeds',
      detailedMessage: "Can you show me my sector 5 exit speeds for the last session?",
    },
    {
      icon: <TrackChangesIcon />,
      text: 'Braking points in Sector 4',
      detailedMessage: "Can you analyze my braking points in Sector 4?",
    },
    {
      icon: <ElectricBoltIcon />,
      text: 'Max RPM with sprocket and pinion',
      detailedMessage: "With which sprocket and pinion did I hit max RPM yesterday?",
      hideMobile: true
    },
    {
      icon: <TuneIcon />,
      text: 'Fastest setup for sector 4',
      detailedMessage: "With what setup have I made sector 4 the fastest today?",
      hideMobile: true
    }
  ],
  [
    {
      icon: <TireRepairIcon />,
      text: 'Rear grip improvement',
      detailedMessage: "I struggled with rear grip in corners. Considering my data and new tires in 25°C, what changes do you suggest?",
    },
    {
      icon: <SpeedIcon />,
      text: 'Fastest driver setups',
      detailedMessage: "Who was the fastest driver on my team yesterday, and what setups did they use?",
    },
    {
      icon: <ElectricBoltIcon />,
      text: 'Max RPM with sprocket and pinion',
      detailedMessage: "With which sprocket and pinion did I hit max RPM yesterday?",
    },
    {
      icon: <TrackChangesIcon />,
      text: 'Braking points in Sector 4',
      detailedMessage: "Can you analyze my braking points in Sector 4?",
    }
  ],
  [
    {
      icon: <SwapHorizIcon />,
      text: 'Axle position change',
      detailedMessage: "Should I change the axle position for the next session?",
    },
    {
      icon: <TuneIcon />,
      text: 'Fastest setup for sector 4',
      detailedMessage: "With what setup have I made sector 4 the fastest today?",
    },
    {
      icon: <DirectionsCarIcon />,
      text: 'Sector 5 exit speeds',
      detailedMessage: "Can you show me my sector 5 exit speeds for the last session?",
    },
    {
      icon: <CompareIcon />,
      text: 'Sector 3 time comparison',
      detailedMessage: "Why am I slower than my teammate in Sector 3?",
    }
  ]
];

function ChatSuggestion({ onClick = () => { } }) {
  const [currentGroupIndex, setCurrentGroupIndex] = useState(0);
  const [isTransitioning, setIsTransitioning] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setIsTransitioning(true); // Trigger the fade-out animation
      setTimeout(() => {
        setCurrentGroupIndex((prevIndex) => (prevIndex + 1) % suggestionGroups.length);
        setIsTransitioning(false); // Trigger the fade-in animation
      }, 500); // Wait for the fade-out animation to complete
    }, 5000); // Change every 5 seconds

    return () => clearInterval(interval);
  }, []);

  const currentSuggestions = suggestionGroups[currentGroupIndex];

  return (
    <Grid container spacing={2}>
    {currentSuggestions.map((card, key) => (
      <Grid
        item
        xs={6}  // Always show two cards per row on small screens
        lg={3}  // Always show four cards per row on large screens
        key={key}
        className={isTransitioning ? 'fade-exit-active' : 'fade-enter-active'}
        display={{
          xs: 'block',  // Ensure cards are always displayed on mobile
          lg: 'block'
        }}
      >
        <Box
          sx={{
            borderRadius: '16px',
            padding: '12px',
            backgroundColor: 'whitesmoke',
            border: '1px solid #5151511a',
            height: '100px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            transition: 'opacity 0.5s ease-in-out', // Smooth opacity transition
            opacity: isTransitioning ? 0 : 1 // Fade effect
          }}
          position="relative"
          overflow="hidden"
        >
          <Button
            onClick={() => onClick(card.detailedMessage)}
            variant="text"
            sx={{
              position: 'absolute',
              top: 0,
              right: 0,
              left: 0,
              bottom: 0
            }}
          ></Button>
          <Stack spacing={1} alignItems="center" textAlign="center">
            {card.icon}
            <Typography variant="body1">
              {card.text}
            </Typography>
          </Stack>
        </Box>
      </Grid>
    ))}
  </Grid>
  
  );
}

export default ChatSuggestion;
