import '../components.css';

import { Bar, BarChart, Legend, XAxis, YAxis } from '@mui/x-charts/BarChart';
import React, { useEffect, useState } from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { Typography, tooltip } from '@mui/material';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';
import { DialogContent } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import axios from "axios";
import { axisClasses } from '@mui/x-charts';
import myUserStore from '../../stores/UserStore';
import { observer } from "mobx-react-lite"
import { styled } from '@mui/material/styles';

const uniqueValues = (data, key) => {
  const unique = new Set(data.map(item => item[key]));
  return [...unique];
};



const MyComponent = ({ driver, race, day, fullName, showTable }) => {
  console.log(driver);
  const selectedName = driver.length > 0 ? fullName.filter(dataItem => driver.includes(dataItem.email)) : fullName;
  console.log("Selected Name", selectedName);
  console.log('Recived day:', day);
  const [selectedData, setSelectedData] = useState([]);
  const [axlepositionVisible, setAxlePositionVisible] = useState(showTable ? true : false);
  const [axletypeVisible, setAxleTypeVisible] = useState(showTable ? true : false);
  const [camberVisible, setCamberVisible] = useState(showTable ? true : false);
  const [casterVisible, setCasterVisible] = useState(showTable ? true : false);
  const [isHidden, setIsHidden] = useState(true);
  const [racingData, setRacingData] = useState([]);
  const [sessionNumber, setSessionNumber] = useState("");
  const [temperatureVisible, setTemperatureVisible] = useState(showTable ? true : false);
  const [isMobile, setIsMobile] = useState(false);
  const [isSmall, setIsSmall] = useState(false);
  const [chartWidth, setChartWidth] = useState(window.innerWidth);
  console.log(chartWidth)
  let message;

  if (!race) {
    message = 'Please select a race';
  } else if (!day) {
    message = 'Please select a day';
  }


  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 850); // Adjust breakpoint as needed
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const extractModifiedLaptime = (racingData) => {
    const modifiedRacingData = racingData.map((item) => {
      const laptimeString = item.laptime;
      if (typeof laptimeString !== 'string') {
        console.error("laptimeString is not a string:", laptimeString);
        return null; // o qualsiasi altro valore significativo
      }
      const [hours, minutes, seconds] = laptimeString.split(":");
      const totalSeconds = parseInt(hours) * 60 + parseInt(minutes) + 0.01 * parseInt(seconds);
      const laptimeCalc = Math.round(totalSeconds * 1000) / 1000;

      // Restituisci un nuovo oggetto con laptimeCalc aggiunto
      return {
        ...item,
        laptimeCalc
      };
    }).filter(item => item !== null); // Rimuovi i valori nulli, se presenti

    return modifiedRacingData;
  };

  // Esempio di utilizzo
  const modifiedRacingData = extractModifiedLaptime(racingData);

  const sortedData = calculateStats(modifiedRacingData).sort((a, b) => a.session_number - b.session_number);
  const racingData1 = [...sortedData];


  console.log("Racing Data 1 with stats", racingData1);
  //const selectedTeam = JSON.parse(localStorage.getItem("selectedTeam"));
  const selectedTeam = myUserStore.getSelectedTeam;
  useEffect(() => {
    // Selected parameters


    // Construct the API URL with selected parameters
    const apiUrl = `${process.env.REACT_APP_API_DOMAIN}/get_pressure_graph_data?email=${driver}&race=${race}&day=${day}&team_id=${selectedTeam.id}`;
    axios
      .get(apiUrl, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          accept: "application/json",
        },
      })
      .then((response) => {
        const data = response.data;
        console.log("API Response:", data); // Log the API response
        if (data !== null) {
          const datasetWithStats = calculateStats(data); // Calculate statistics
          console.log("RacingData", data); // Log dataset with statistics
          setRacingData(data); // Update state with dataset with statistics
        }
        setAxlePositionVisible(showTable ? true : false);
        setAxleTypeVisible(showTable ? true : false);
        setCamberVisible(showTable ? true : false);
        setCasterVisible(showTable ? true : false);
        setTemperatureVisible(showTable ? true : false);
      })
      .catch((error) => {
        console.error("Query Error:", error);
      });

  }, [driver, race, day, showTable]);



  const handleButtonClick = (buttonType) => {
    // Update the visibility of the clicked button
    if (buttonType === 'axleposition') {
      setAxlePositionVisible(!axlepositionVisible);
    } else if (buttonType === 'axletype') {
      setAxleTypeVisible(!axletypeVisible);
    }
    if (buttonType === 'camber') {
      setCamberVisible(!camberVisible);
    } else if (buttonType === 'caster') {
      setCasterVisible(!casterVisible);
    } else if (buttonType === 'temperature') { // Handle temperature button click
      setTemperatureVisible(!temperatureVisible);
    }

    setButtonVisibility((prevVisibility) => ({
      ...prevVisibility,
      [buttonType]: !prevVisibility[buttonType],
    }));
  };
  const [buttonVisibility, setButtonVisibility] = useState({
    axleposition: false,
    axletype: false,
    camber: false,
    caster: false,
    temperature: false,
  });

  // const filteredData = dataset1.filter(item =>
  //   (!driver || (item.mail && driver.includes(item.mail)))
  // );


  useEffect(() => {
    // Create an object to store data for each unique email and session number combination
    const emailSessionMap = new Map();

    // const filteredData = dataset1.filter(item =>
    //   (!driver || (item.mail && driver.includes(item.mail)))
    // );


    // Iterate through the filtered data to populate the email session map
    racingData1.forEach(item => {
      const { email, session_number } = item;
      const key = `${email}-${session_number}`;



      if (!emailSessionMap.has(key)) {
        emailSessionMap.set(key, { email: item.email, session_number });
      }

      const sessionData = emailSessionMap.get(key);


      // Concatenate the values if the field already exists
      if (axlepositionVisible && sessionData.axle_position) {
        sessionData.axle_position += ` |  ${item.axle_position}`;
      } else if (axlepositionVisible) {
        sessionData.axle_position = `${item.axle_position}`;
      }

      if (axletypeVisible && sessionData.axle_type) {
        sessionData.axle_type += ` |  ${item.axle_type}`;
      } else if (axletypeVisible) {
        sessionData.axle_type = `${item.axle_type}`;
      }

      if (camberVisible && sessionData.camber) {
        sessionData.camber += ` |  ${item.camber}`;
      } else if (camberVisible) {
        sessionData.camber = `${item.camber}`;
      }

      if (casterVisible && sessionData.caster) {
        sessionData.caster += ` |  ${item.caster}`;
      } else if (casterVisible) {
        sessionData.caster = `${item.caster}`;
      }
      if (temperatureVisible && sessionData.temperature) {
        sessionData.temperature += ` |  ${item.temperature}`;
      } else if (temperatureVisible) {
        sessionData.temperature = `${item.temperature}`;
      }

      emailSessionMap.set(key, sessionData);
    });

    // Convert the map values to an array of selected data
    const newData = Array.from(emailSessionMap.values());
    setSelectedData(newData);
  }, [axlepositionVisible, axletypeVisible, camberVisible, casterVisible, temperatureVisible, racingData]);




  function calculateStats(dataset) {
    const stats = {};

    dataset.forEach(item => {
      const session_number = item.session_number;

      if (!stats[session_number]) {
        stats[session_number] = {
          pressure_hot: {
            min: Number.MAX_SAFE_INTEGER,
            max: Number.MIN_SAFE_INTEGER,
            sum: 0,
            count: 0
          },
          laptimeCalc: {
            min: Number.MAX_SAFE_INTEGER,
            max: Number.MIN_SAFE_INTEGER,
            sum: 0,
            count: 0
          },
          pressure_cold: {
            min: Number.MAX_SAFE_INTEGER,
            max: Number.MIN_SAFE_INTEGER,
            sum: 0,
            count: 0
          },
          temperature: {
            min: Number.MAX_SAFE_INTEGER,
            max: Number.MIN_SAFE_INTEGER,
            sum: 0,
            count: 0
          }
        };
      }

      const sessionStats = stats[session_number];

      // Update min_rpm stats
      if (item.pressure_hot < sessionStats.pressure_hot.min) {
        sessionStats.pressure_hot.min = item.pressure_hot;
      }
      if (item.pressure_hot > sessionStats.pressure_hot.max) {
        sessionStats.pressure_hot.max = item.pressure_hot;
      }
      sessionStats.pressure_hot.sum += item.pressure_hot;
      sessionStats.pressure_hot.count++;

      // Update laptime stats
      if (item.laptimeCalc < sessionStats.laptimeCalc.min) {
        sessionStats.laptimeCalc.min = item.laptimeCalc;
      }
      if (item.laptimeCalc > sessionStats.laptimeCalc.max) {
        sessionStats.laptimeCalc.max = item.laptimeCalc;
      }
      sessionStats.laptimeCalc.sum += item.laptimeCalc;
      sessionStats.laptimeCalc.count++;

      // Update min_exhaust stats
      if (item.pressure_cold < sessionStats.pressure_cold.min) {
        sessionStats.pressure_cold.min = item.pressure_cold;
      }
      if (item.pressure_cold > sessionStats.pressure_cold.max) {
        sessionStats.pressure_cold.max = item.pressure_cold;
      }
      sessionStats.pressure_cold.sum += item.pressure_cold;
      sessionStats.pressure_cold.count++;

      // Update max_exhaust stats

      // Upndate temperature stats
      if (item.temperature < sessionStats.temperature.min) {
        sessionStats.temperature.min = item.temperature;
      }
      if (item.temperature > sessionStats.temperature.max) {
        sessionStats.temperature.max = item.temperature;
      }
      sessionStats.temperature.sum += item.temperature;
      sessionStats.temperature.count++;
    });

    // Calculate averages and other metrics for each session
    for (const session_number in stats) {
      const sessionStats = stats[session_number];

      // Calculate averages for min_rpm and laptime
      sessionStats.pressure_hot.avg = Math.round((sessionStats.pressure_hot.sum / sessionStats.pressure_hot.count) * 1000) / 1000;
      sessionStats.laptimeCalc.avg = Math.round((sessionStats.laptimeCalc.sum / sessionStats.laptimeCalc.count) * 1000) / 1000;
      sessionStats.pressure_cold.avg = Math.round((sessionStats.pressure_cold.sum / sessionStats.pressure_cold.count) * 1000) / 1000;



      // Calculate additional metrics for min_rpm and laptime
      // Calculate additional metrics for min_rpm, laptime, min_exhaust, max_exhaust, and max_speed
      sessionStats.pressure_hot.avg_rpm1 = Math.round((sessionStats.pressure_hot.avg - sessionStats.pressure_hot.min) * 1000) / 1000;
      sessionStats.pressure_hot.max_rpm1 = Math.round((sessionStats.pressure_hot.max - sessionStats.pressure_hot.avg) * 1000) / 1000;
      sessionStats.laptimeCalc.avg_lap1 = Math.round((sessionStats.laptimeCalc.avg - sessionStats.laptimeCalc.min) * 1000) / 1000;
      sessionStats.laptimeCalc.max_lap1 = Math.round((sessionStats.laptimeCalc.max - sessionStats.laptimeCalc.avg) * 1000) / 1000;

      sessionStats.pressure_cold.avg_exhaust1 = Math.round((sessionStats.pressure_cold.avg - sessionStats.pressure_cold.min) * 1000) / 1000;
      sessionStats.pressure_cold.max_exhaust1 = Math.round((sessionStats.pressure_cold.max - sessionStats.pressure_cold.avg) * 1000) / 1000;


    }

    // Convert stats object to array
    const statsArray = Object.values(stats);


    const datasetWithStats = dataset.map(item => {
      return {
        ...item,
        stats: stats[item.session_number]
      };
    });

    return datasetWithStats;
  }


  const xLabels = [
    'Session 1',
    'Session 2',
    'Session 3',
    'Session 4',
    'Session 5',
    'Session 6',

  ];

  const seriesA = {
    data: [5, 2.5, 1],
    label: 'Series A',
  };
  const seriesB = {
    data: [0, 0, 0], // Set all values to 0 to avoid stacking
    label: 'Series B (Reference)',
  };
  const seriesC = {
    data: [0, 0, 0], // Set all values to 0 to avoid stacking
    label: 'Series C (Reference)',
  };

  const [stackVisibility, setStackVisibility] = useState({
    stack1: true,
    stack2: true,
    stack3: true,
    stack4: true,
    stack5: true,
  });

  const handleToggleStack = (stack) => {
    setStackVisibility({ ...stackVisibility, [stack]: !stackVisibility[stack] });
  };
  const [activeButton, setActiveButton] = useState(null);
  let add;
  const valueFormatter = (value, add) => `${value + add}`;

  const theme = createTheme({
    palette: {
      primary: { main: '#00897B', light: '#B2DFDB', dark: '#004D40' },
      secondary: { main: '#0057B2', },
      success: { main: '#7B1FA2', },
      info: { main: '#AD1457' },
      error: { main: '#E64A19' }

    },
  });
  const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
  }));


  const sessionNumbers = []; // Array per tenere traccia dei session number già visti
  let numberOfCells = 0;
  const handleZoomIn = () => {
    setChartWidth((prevWidth) => prevWidth + 100);
  };

  const handleZoomOut = () => {
    setChartWidth((prevWidth) => Math.max(prevWidth - 100, 100));
  };
  return (


    <div className='frame'>


      <><div className='textEngine'>

        <Typography Variant="overline" Gutter Bottom='False' Content="Typography" className='customizeText'> CUSTOMIZE THE VISUALIZATION </Typography>
        <div className='engineButtons'>
          <ThemeProvider theme={theme}>
            <Chip onClick={() => handleToggleStack('stack1')} label="HOT PRESSURE" color='primary' size="medium" variant={stackVisibility['stack1'] ? "filled" : "outlined"} checked={stackVisibility.stack1} />
            <Chip onClick={() => handleToggleStack('stack2')} label="LAP TIME" color="secondary" size="medium" variant={stackVisibility['stack2'] ? "filled" : "outlined"} checked={stackVisibility.stack2} />
            <Chip onClick={() => handleToggleStack('stack3')} label="COLD PRESSURE" color='success' size="medium" variant={stackVisibility['stack3'] ? "filled" : "outlined"} checked={stackVisibility.stack3} />
            <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '10px' }}>
              <ZoomInIcon variant="contained" onClick={handleZoomOut} style={{ cursor: 'pointer', marginRight: '10px', width: 40, height: 40 }} />

              <ZoomOutIcon variant="contained" onClick={handleZoomIn} style={{ cursor: 'pointer', marginRight: '10px', width: 40, height: 40 }} />

            </div>
          </ThemeProvider>
        </div>
      </div>

        {!(race && day) ? (
          <div className="grid-demo-no-data-container">
            {message && (
              <>
                <p className="grid-demo-no-data-text">{message}</p>
                <i className="grid-demo-info-icon fas fa-info-circle"></i>
              </>
            )}
          </div>)

          : (<div className='graphContainer' style={{ display: 'flex', justifyContent: 'center' }}>


            <BarChart
              tooltip={{ trigger: 'item' }}
              slotProps={{ legend: { hidden: isHidden } }}
              axisHighlight={{
                y: 'line', // Or 'none'
              }}
              width={chartWidth}
              height={650}
              series={[
                stackVisibility.stack1 && racingData1.length > 0 && {
                  dataset: { racingData1 },
                  data: racingData1.map((item) => Math.round((item.stats.pressure_hot.max * 33 / 100) * 1000) / 1000),

                  stack: 'stack1',
                  yAxisKey: 'leftAxisId',
                  label: 'Hot Pressure lower',
                  color: '#B2DFDB',

                  valueFormatter: (value) => {
                    const containsItem = racingData1.find(dataItem => dataItem.stats.pressure_hot.max === Math.round((value * 100 / 33) * 1000) / 1000);
                    const correspondingItem = racingData1.find(dataItem => dataItem.pressure_hot === containsItem.stats.pressure_hot.min);
                    const name = racingData1.find(dataItem => dataItem.email === correspondingItem.email);
                    return ` ( ${name.name} ${name.surname})  ${correspondingItem.pressure_hot}`;
                  },
                  id: 'pvId',
                },
                stackVisibility.stack1 && racingData1.length > 0 && {

                  dataset: { racingData1 },
                  data: racingData1.map((item) => Math.round((item.stats.pressure_hot.max * 33 / 100) * 1000) / 1000),
                  valueFormatter: (value) => {
                    const containsItem = racingData1.find(dataItem => dataItem.stats.pressure_hot.max === Math.round((value * 100 / 33) * 1000) / 1000);
                    return ` ${containsItem.stats.pressure_hot.avg}`;
                  },
                  stack: 'stack1',
                  yAxisKey: 'leftAxisId',
                  label: 'Hot Pressure avg',
                  color: '#00897B',

                },
                stackVisibility.stack1 && racingData1.length > 0 && {
                  dataset: { racingData1 },
                  data: racingData1.map((item) => Math.round((item.stats.pressure_hot.max * 33 / 100) * 1000) / 1000),
                  valueFormatter: (value) => {
                    const containsItem = racingData1.find(dataItem => dataItem.stats.pressure_hot.max === Math.round((value * 100 / 33) * 1000) / 1000);
                    const correspondingItem = racingData1.find(dataItem => dataItem.pressure_hot === containsItem.stats.pressure_hot.max);
                    const name = racingData1.find(dataItem => dataItem.email === correspondingItem.email);
                    return ` ( ${name.name} ${name.surname})  ${correspondingItem.pressure_hot}`;
                  },
                  stack: 'stack1',
                  yAxisKey: 'leftAxisId',
                  label: 'Hot Pressure  higher',
                  color: '#004D40',

                },
                stackVisibility.stack2 && racingData1.length > 0 && {
                  dataset: { racingData1 },
                  data: racingData1.map((item) => Math.round((item.stats.laptimeCalc.max * 33 / 100) * 1000) / 1000),
                  stack: 'stack2',
                  yAxisKey: (stackVisibility['stack2'] && !stackVisibility['stack1'] && !stackVisibility['stack3']) || (stackVisibility['stack2'] && !stackVisibility['stack1'] && stackVisibility['stack5']) ? 'leftAxisId' : 'rightAxisId',
                  label: 'Lap Time min',
                  color: '#BBDEFB',
                  valueFormatter: (value) => {

                    const rem = Math.round((value * 100 / 33) * 100) / 100;
                    const containsItem = racingData1.find(dataItem => dataItem.stats.laptimeCalc.max === Math.round((value * 100 / 33) * 100) / 100);
                    const correspondingItem = racingData1.find(dataItem => dataItem.laptimeCalc === containsItem.stats.laptimeCalc.min);
                    const name = racingData1.find(dataItem => dataItem.email === correspondingItem.email);
                    return ` ( ${name.name} ${name.surname})  ${correspondingItem.laptime}`;
                  },
                },
                stackVisibility.stack2 && racingData1.length > 0 && {
                  dataset: { racingData1 },
                  data: racingData1.map(item => Math.round((item.stats.laptimeCalc.max * 33 / 100) * 1000) / 1000),
                  valueFormatter: (value) => {
                    const containsItem = racingData1.find(dataItem => dataItem.stats.laptimeCalc.max === Math.round((value * 100 / 33) * 100) / 100);
                    return `        ${containsItem.stats.laptimeCalc.avg}`;
                  },
                  stack: 'stack2',
                  yAxisKey: (stackVisibility['stack2'] && !stackVisibility['stack1'] && !stackVisibility['stack3']) || (stackVisibility['stack2'] && !stackVisibility['stack1'] && stackVisibility['stack5']) ? 'leftAxisId' : 'rightAxisId',
                  label: 'Lap Time avg',
                  color: '#1E88E5',
                },
                stackVisibility.stack2 && racingData1.length > 0 && {
                  dataset: { racingData1 },
                  data: racingData1.map(item => Math.round((item.stats.laptimeCalc.max * 33 / 100) * 1000) / 1000),
                  valueFormatter: (value, index) => {

                    const rem = Math.round((value * 100 / 33) * 100) / 100;
                    const containsItem = racingData1.find(dataItem => dataItem.stats.laptimeCalc.max === Math.round((value * 100 / 33) * 100) / 100);
                    const correspondingItem = racingData1.find(dataItem => dataItem.laptimeCalc === containsItem.stats.laptimeCalc.max);


                    const name = racingData1.find(dataItem => dataItem.email === correspondingItem.email);
                    return ` ( ${name.name} ${name.surname}) ${correspondingItem.laptime}`;
                  },
                  stack: 'stack2',
                  yAxisKey: (stackVisibility['stack2'] && !stackVisibility['stack1'] && !stackVisibility['stack3']) || (stackVisibility['stack2'] && !stackVisibility['stack1'] && stackVisibility['stack5']) ? 'leftAxisId' : 'rightAxisId',
                  label: 'Lap Time max',
                  color: '#0D47A1',

                },

                stackVisibility.stack3 && racingData1.length > 0 && {
                  dataset: { racingData1 },
                  data: racingData1.map((item) => Math.round((item.stats.pressure_cold.max * 33 / 100) * 1000) / 1000),
                  stack: 'stack3',
                  yAxisKey: (!stackVisibility['stack2'] && !stackVisibility['stack1'] && stackVisibility['stack3']) || (stackVisibility['stack2'] && !stackVisibility['stack1'] && stackVisibility['stack3']) ? 'leftAxisId' : 'rightAxisId',
                  label: 'Cold Pressure  lower',

                  valueFormatter: (value) => {
                    const containsItem = racingData1.find(dataItem => dataItem.stats.pressure_cold.max === Math.round((value * 100 / 33) * 1000) / 1000);
                    const correspondingItem = racingData1.find(dataItem => dataItem.pressure_cold === containsItem.stats.pressure_cold.min);
                    const name = racingData1.find(dataItem => dataItem.email === correspondingItem.email);
                    return ` ( ${name.name} ${name.surname})  ${correspondingItem.pressure_cold}`;
                  },
                  color: '#E1BEE7',
                },

                stackVisibility.stack3 && racingData1.length > 0 && {
                  dataset: { racingData1 },
                  data: racingData1.map((item) => Math.round((item.stats.pressure_cold.max * 33 / 100) * 1000) / 1000),
                  valueFormatter: (value) => {
                    const containsItem = racingData1.find(dataItem => dataItem.stats.pressure_cold.max === Math.round((value * 100 / 33) * 1000) / 1000);
                    return ` ${containsItem.stats.pressure_cold.avg}`;
                  },
                  stack: 'stack3',
                  yAxisKey: (!stackVisibility['stack2'] && !stackVisibility['stack1'] && stackVisibility['stack3']) || (stackVisibility['stack2'] && !stackVisibility['stack1'] && stackVisibility['stack3']) ? 'leftAxisId' : 'rightAxisId',
                  label: 'Cold Pressure  avg',
                  color: '#8E24AA',
                },
                stackVisibility.stack3 && racingData1.length > 0 && {
                  dataset: { racingData1 },
                  data: racingData1.map((item) => Math.round((item.stats.pressure_cold.max * 33 / 100) * 1000) / 1000),
                  valueFormatter: (value) => {
                    const containsItem = racingData1.find(dataItem => dataItem.stats.pressure_cold.max === Math.round((value * 100 / 33) * 1000) / 1000);
                    const correspondingItem = racingData1.find(dataItem => dataItem.pressure_cold === containsItem.stats.pressure_cold.max);
                    const name = racingData1.find(dataItem => dataItem.email === correspondingItem.email);
                    return ` ( ${name.name} ${name.surname})  ${correspondingItem.pressure_cold}`;
                  },
                  stack: 'stack3',
                  yAxisKey: (!stackVisibility['stack2'] && !stackVisibility['stack1'] && stackVisibility['stack3']) || (stackVisibility['stack2'] && !stackVisibility['stack1'] && stackVisibility['stack3']) ? 'leftAxisId' : 'rightAxisId',
                  label: 'Cold Pressure  higher',
                  color: '#4A148C',
                },
              ].filter(Boolean)}
              xAxis={[{ data: racingData1.map((item) => item.session_number), scaleType: 'band' }]}
              yAxis={[
                { id: 'leftAxisId', domain: [-100, 1000000000000000000] }, // Adjust the domain values as needed
                { id: 'rightAxisId', domain: [-100, 1000000000000000000] }, // Adjust the domain values as needed
              ]}
              rightAxis="rightAxisId"





            >

            </BarChart>
          </div>)}</>
      {racingData1?.length > 0 && (<div>
        <div className='custom-table'>
          <Typography Variant="overline" Gutter Bottom='False' Content="Typography" className='customizeText'> CUSTOMIZE THE TABLE   </Typography>

        </div>

        <div className='table-buttons'>
          <ThemeProvider theme={theme}>
            <Box gap={4}>
              <Chip onClick={() => handleButtonClick('axleposition')} label="AXLE POSITION" sx={{ margin: 1 }} color='default' size="medium" variant={axlepositionVisible ? "contained" : "outlined"} />
              <Chip onClick={() => handleButtonClick('axletype')} label="AXLE TYPE" sx={{ margin: 1 }} color="default" size="medium" variant={axletypeVisible ? "contained" : "outlined"} />
              <Chip onClick={() => handleButtonClick('camber')} label="CAMBER" sx={{ margin: 1 }} color="default" size="medium" variant={camberVisible ? "contained" : "outlined"} />
              <Chip onClick={() => handleButtonClick('caster')} label="CASTER" color="default" sx={{ margin: 1 }} size="medium" variant={casterVisible ? "contained" : "outlined"} />
              {!isNaN(selectedData.temperature) && (<Chip onClick={() => handleButtonClick('temperature')} label="TEMPERATURE" sx={{ margin: 1 }} color="default" size="medium" variant={temperatureVisible ? "contained" : "outlined"} />)}



            </Box>
          </ThemeProvider>
        </div>


        <TableContainer>
          <Table>
            <TableHead>
              <TableRow sx={{ background: 'rgba(0, 0, 0, 0.00)', border: '1px solid lightgrey', padding: '20px' }}>
                <TableCell />
                {selectedData.map((item, index) => {
                  if (sessionNumbers.includes(item.session_number)) {
                    return null; // Se il session number è già stato visto, non fare nulla
                  } else {
                    sessionNumbers.push(item.session_number); // Aggiungi il session number all'array dei già visti
                    numberOfCells++;
                    return (
                      <TableCell key={`session-number-${index}`}>
                        Session Number {item.session_number}
                        <div style={{ display: 'row', color: 'black', fontWeight: 'normal' }}>
                          {!isNaN(item.temperature) && (<Typography>
                            {item.temperature} °
                          </Typography>)}
                        </div>
                      </TableCell>
                    );
                  }
                })}

              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                {/*<TableCell sx={{ borderLeft: '1px solid lightgrey' }} />
              {Array.from({ length: numberOfCells }, (_, index) => (
                <TableCell key={index} sx={{ borderRight: '1px solid lightgrey' }}>
                  Runs

                </TableCell>
              ))}
              ))}*/}
              </TableRow>
              {axlepositionVisible && <TableCell align='right' sx={{ fontWeight: 'bold', borderLeft: '1px solid lightgrey', padding: '12px' }}>AXLE POSITION</TableCell>}
              {axlepositionVisible && <TableCell align='right' colSpan={selectedData.length + 1} sx={{ fontWeight: 'bold', borderRight: '1px solid lightgrey', padding: '12px' }}></TableCell>}


              {axlepositionVisible && selectedName && selectedName.map((selectedName, index) => (

                <TableRow sx={{ border: '1px solid lightgrey' }} key={`driver-${index}`}>
                  <TableCell sx={{ border: '1px solid lightgrey' }}>{selectedName.surname}</TableCell>
                  {selectedData.filter(item => item.email === selectedName.email).map((item, index) => (
                    item.axle_position && item.axle_position.includes('|') ? (
                      <LightTooltip placement='top' title={"More runs in the same session"} arrow>
                        <TableCell key={`driver-${index}-axle_position`}>{item.axle_position}</TableCell>
                      </LightTooltip>
                    ) : (
                      <TableCell key={`driver-${index}-axle_position`}>{item.axle_position}</TableCell>
                    )
                  ))}
                </TableRow>
              ))}

              {axletypeVisible && <TableCell align='right' sx={{ fontWeight: 'bold', borderLeft: '1px solid lightgrey', padding: '12px' }}>AXLE TYPE</TableCell>}
              {axletypeVisible && <TableCell align='right' colSpan={selectedData.length + 1} sx={{ fontWeight: 'bold', borderRight: '1px solid lightgrey', padding: '12px' }}></TableCell>}
              {axletypeVisible && selectedName && selectedName.map((selectedName, index) => (

                <TableRow sx={{ border: '1px solid lightgrey' }} key={`driver-${index}`}>
                  <TableCell sx={{ border: '1px solid lightgrey' }}>{selectedName.surname}</TableCell>
                  {selectedData.filter(item => item.email === selectedName.email).map((item, index) => (
                    item.axle_type && item.axle_type.includes('|') ? (
                      <LightTooltip placement='top' title={"More runs in the same session"} arrow>
                        <TableCell key={`driver-${index}-axle_type`}>{item.axle_type}</TableCell>
                      </LightTooltip>
                    ) : (
                      <TableCell key={`driver-${index}-axle_type`}>{item.axle_type}</TableCell>
                    )
                  ))}
                </TableRow>
              ))}


              {camberVisible && <TableCell align='right' sx={{ fontWeight: 'bold', borderLeft: '1px solid lightgrey', padding: '12px' }}>CAMBER</TableCell>}
              {camberVisible && <TableCell align='right' colSpan={selectedData.length + 1} sx={{ fontWeight: 'bold', borderRight: '1px solid lightgrey', padding: '12px' }}></TableCell>}
              {camberVisible && selectedName && selectedName.map((selectedName, index) => (

                <TableRow sx={{ border: '1px solid lightgrey' }} key={`driver-${index}`}>
                  <TableCell sx={{ border: '1px solid lightgrey' }}>{selectedName.surname}</TableCell>
                  {selectedData.filter(item => item.email === selectedName.email).map((item, index) => (
                    item.camber && item.camber.includes('|') ? (
                      <LightTooltip placement='top' title={"More runs in the same session"} arrow>
                        <TableCell key={`driver-${index}-camber`}>{item.camber}</TableCell>
                      </LightTooltip>
                    ) : (
                      <TableCell key={`driver-${index}-camber`}>{item.camber}</TableCell>
                    )
                  ))}
                </TableRow>
              ))}

              {casterVisible && <TableCell align='right' sx={{ fontWeight: 'bold', borderLeft: '1px solid lightgrey', padding: '12px' }}>PINION</TableCell>}
              {casterVisible && <TableCell align='right' colSpan={selectedData.length + 1} sx={{ fontWeight: 'bold', borderRight: '1px solid lightgrey', padding: '12px' }}></TableCell>}
              {casterVisible && selectedName && selectedName.map((selectedName, index) => (

                <TableRow sx={{ border: '1px solid lightgrey' }} key={`driver-${index}`}>
                  <TableCell sx={{ border: '1px solid lightgrey' }}>{selectedName.surname}</TableCell>
                  {selectedData.filter(item => item.email === selectedName.email).map((item, index) => (
                    item.caster && item.caster.includes('|') ? (
                      <LightTooltip placement='top' title={"More runs in the same session"} arrow>
                        <TableCell key={`driver-${index}-caster`}>{item.caster}</TableCell>
                      </LightTooltip>
                    ) : (
                      <TableCell key={`driver-${index}-caster`}>{item.caster}</TableCell>
                    )
                  ))}
                </TableRow>
              ))}
            </TableBody>

          </Table>
        </TableContainer>
      </div>)}
    </div>
  );

};

export default observer(MyComponent);
