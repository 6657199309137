import { Box, Button, Dialog, DialogContent, Stack, Step, StepLabel, Stepper } from '@mui/material';
import React, { useEffect, useState } from 'react';

import SelectDay from './Steps/SelectDay';
import SelectDriver from './Steps/SelectDriver';
import SelectRace from './Steps/SelectRace';
import SelectTelemetry from './Steps/SelectTelemetry';
import axios from 'axios';
import myUserStore from 'stores/UserStore';
import { observer } from "mobx-react-lite";



const DriversDates = ({
  isOpen = '',
  laps = [],
  driverSessions = [],
  race = '',
  day = '',
  arrayChange,
  step,
  stepPayload,
  setStepPayload,
  setIsOpen,
  setStep,
  selectedDriver,
  completed,
  setCompleted,
  isDriverAnalytics,
  isTry,
  notTelem, 
  onComplete = () => { }
}) => {
  const selectedTeam = myUserStore.getSelectedTeam;
  const showPersonalButton = myUserStore.isPersonalSpace;

  //const [openDialog, setOpenDialog] = useState(isOpen)
  let steps;
  if (isDriverAnalytics) {
    steps = ['Select race', 'Select day', 'Select driver', 'Select telemetry']

  } else {
    steps = ['Select race', 'Select day', 'Select driver']
  }


  const handleComplete = () => {
    onComplete(stepPayload)
    handleClose()
    setCompleted(true)
  }

  const handleChooseAll= () => {
    setStepPayload((prev) => ({
      ...prev,
      ['drivers']: []
    }))
    onComplete(stepPayload)
    handleClose()
    setCompleted(true)
    
  }

  const handleClose = () => {
    setIsOpen(false)
    arrayChange(stepPayload)
  }

  const handleStepChange = (who, value, goNext = true) => {
    setStepPayload((prev) => ({
      ...prev,
      [who]: value
    }))
    goNext && handleStepNext()
  }

  const handleStepBack = () => {
    setStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepNext = () => {
    setStep((prevActiveStep) => prevActiveStep + 1);
  };

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth={"md"}
      >
        <DialogContent >
          <Stepper activeStep={step} alternativeLabel sx={{ mt: 2 }}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <Box p={1}>
            {step === 0 ? <SelectRace isTry={isTry} selectedTeam={selectedTeam} activeRace={stepPayload.race} onChange={(newValue) => handleStepChange('race', newValue)} /> : null}
            {step === 1 ? <SelectDay isTry={isTry} activeRace={stepPayload.race} activeDay={stepPayload.day} onChange={(newValue) => handleStepChange('day', newValue)} /> : null}
            {step === 2 ? <SelectDriver  notTelem={notTelem} isTry={isTry} selectedTeam={selectedTeam} activeDriver={stepPayload.drivers} onChange={(newValue) => handleStepChange('drivers', newValue, false)} /> : null}
            {step === 3 && isDriverAnalytics ? <SelectTelemetry isTry={isTry} selectedDriver={selectedDriver} selectedTeam={selectedTeam} activeDriver={stepPayload.drivers} activeDay={stepPayload.day} activeRace={stepPayload.race} laps={stepPayload.laps} driverSessions={stepPayload.driverSessions} completed={completed} onChangeDriverSessions={(newValue) => handleStepChange('driverSessions', newValue, false)} onChange={(newValue) => handleStepChange('laps', newValue, false)} /> : null}
            <Stack direction={"row"} spacing={2} alignItems={'center'} justifyContent={'center'} mt={3}>
              <Button variant="text" disabled={step === 0} onClick={() => handleStepBack()}>Prev</Button>


              {
                step === (isDriverAnalytics ? 3 : 2)
                  ? <Button variant="outlined" onClick={() => handleComplete()}>COMPLETE</Button>
                  : null
              }
              {/*{
                step === (isDriverAnalytics ? 25 : 2)
                  ? <Button variant="outlined" onClick={() => handleChooseAll()}>CHOOSE ALL</Button>
                  : null
              }*/}
              {
                step === (isDriverAnalytics ? 2 : 1)? <Button variant="outlined" disabled={stepPayload.drivers.length === 0} onClick={() => handleStepNext()}>Next</Button> : null
              }


            </Stack>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default observer(DriversDates);
