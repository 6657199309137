import "./components.css";

import { Box, Button, Grid, Stack, TextField, Typography, Theme } from "@mui/material";

// import { Button } from "./Button";
import { Link } from "react-router-dom";
import NewLogo from './NewLogo.png'
import React from "react";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
/* codice Luca senza link ai social
const footerLinks = [{
  title:'About Us',
  links: [
    {
      label: 'Clients',
      href: '/clients'
    },
    {
      label: 'Terms of Service',
      href: '/terms-of-service'
    }
  ]
},
{
  title:'Contact us',
  links: [
    {
      label: 'Support',
      href: '/contact-us'
    }
  ]
},
{
  title:'Social media',
  links: [
    {
      label: 'Facebook',
      href: '/'
    },
    {
      label: 'Instagram',
      href: '/'
    },
    {
      label: 'X',
      href: '/'
    },
    {
      label: 'YouTube',
      href: '/'
    },
  ]
},
{
  title:'Leave us a testimonial',
  links: [
    {
      label: 'Submit Video',
      href: '/'
    },
    {
      label: 'Ambassadors',
      href: '/'
    }
  ]
}]

function Footer() {
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'))
  return (
    <div className="footer-container">
      <div className="footer-line"></div>
      <Stack spacing={{ xs: '40px', md: '90px'}} className="container-lg">
        <section className="footer-subscription">
          <Grid container spacing={4} alignItems={'center'}>
            <Grid item xs={12} lg={8}>
              <Stack>
                <Typography variant="h4">Join AiMotor</Typography>
                <Typography variant="p">Subscribe to AiMotor newsletter to receive the latest news and announcements</Typography>
              </Stack>
            </Grid>
            <Grid item xs={12} lg={4}>
              <form>
                <Stack direction="row" spacing={3} justifyContent={'center'} alignItems={'center'}>
                  <input
                    className="footer-input"
                    name="email"
                    type="email"
                    placeholder="Your Email"
                  />
                  <Button variant="contained" color="secondary">
                    SUBSCRIBE
                  </Button>
                </Stack>
                <Typography as="p" variant="caption" mt="4px" textAlign={'left'} sx={{ opacity: 0.7 }}>
                  You can unsubscribe at any time.
                </Typography>
              </form>
            </Grid>
          </Grid>
        </section>
        <section className="footer-links">
          <Grid container spacing={4}>
            <Grid item md={5} hidden={!isDesktop}>
              <Link to="/" className="footerNew-logo">
                <img src={NewLogo} alt="AiMotors logo" />
              </Link>
              <Typography as={'p'} variant="caption" mt={2}>
              </Typography>
            </Grid>
            <Grid item container md rowSpacing={{ xs: 4, md: 0}}>
              {
                footerLinks.map((item, index) => (
                  <Grid item xs={12} md={3} key={index}>
                    <Stack spacing={2}>
                      <Typography variant="subtitle2" color="white" fontWeight={600} sx={{ opacity: 0.6 }}>
                        {item.title}
                      </Typography>
                      <Stack spacing={1} className="footer-link-items">
                        {
                          item.links.map((link, j) => (
                            <Link key={`${index}-${j}`} to={link.href}>{link.label}</Link>
                          ))
                        }
                      </Stack>
                    </Stack>
                  </Grid>
                ))
              }
            </Grid>
          </Grid>
        </section>
        <section className="social-media">
          <div className="social-media-wrap">
            <Grid container>
              <Grid item xs={12} sm={6}>
                <small className="website-rights">© 2024. All right reserved.</small>
              </Grid>
              <Grid item xs={12} sm={6} mt={{ xs: 1, sm: 0}}>
                <Stack className="social-icons" direction="row" spacing={4} ml={'auto'} justifyContent={'end'}>
                  <Link
                    className="social-icon-link facebook"
                    to="/"
                    target="_blank"
                    aria-label="Facebook"
                  >
                    <i className="fab fa-facebook-f" />
                  </Link>
                  <Link
                    className="social-icon-link instagram"
                    to="/"
                    target="_blank"
                    aria-label="Instagram"
                  >
                    <i className="fab fa-instagram" />
                  </Link>
                  <Link
                    className="social-icon-link youtube"
                    to="/"
                    target="_blank"
                    aria-label="Youtube"
                  >
                    <i className="fab fa-youtube" />
                  </Link>
                  <Link
                    className="social-icon-link twitter"
                    to="/"
                    target="_blank"
                    aria-label="Twitter"
                  >
                    <i className="fab fa-twitter" />
                  </Link>
                  <Link
                    className="social-icon-link twitter"
                    to="/"
                    target="_blank"
                    aria-label="LinkedIn"
                  >
                    <i className="fab fa-linkedin" />
                  </Link>
                </Stack>
              </Grid>
            </Grid>
          </div>
        </section>
      </Stack>
    </div>
  );
}

export default Footer;
*/

const footerLinks = [{
  title: 'About Us',
  links: [
    {
      label: 'Clients',
      href: '/clients'
    },

  ]
},
{
  title: 'Contact us',
  links: [
    {
      label: 'Support',
      href: '/contact-us'
    },
  
  ]
},
{
  title: 'Terms & policies',
  links: [
    {
      label: 'Terms of Service',
      href: '/terms-of-service'
    },
    {
      label: 'Privacy policy',
      href: '/privacy'
    }
  ]
},
{
  title: 'Social media',
  links: [
    {
      label: 'Instagram',
      href: 'https://www.instagram.com/aimotor.kart/'
    },
    {
      label: 'Facebook',
      href: 'https://www.facebook.com/profile.php?id=61554863766530'
    }/*,
    {
      label: 'X',
      href: '/'
    },
    {
      label: 'YouTube',
      href: '/'
    },*/
  ]
}/*,
{
  title: 'Leave us a testimonial',
  links: [
    {
      label: 'Submit Video',
      href: '/'
    },
    {
      label: 'Ambassadors',
      href: '/'
    }
  ]
}*/]

function Footer() {
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'))
  return (
    <div className="footer-container">
      <div className="footer-line"></div>
      <Stack spacing={{ xs: '40px', md: '90px' }} className="container-lg">
        <section className="footer-subscription">
          <Grid container spacing={4} alignItems={'center'}>
            <Grid item xs={12} lg={8}>
              <Stack>
                <Typography variant="h4">Join AiMotor</Typography>
                {/* <Typography variant="p">Subscribe to AiMotor newsletter to receive the latest news and announcements</Typography> */}
              </Stack>
            </Grid>
            {/* <Grid item xs={12} lg={4}>
              <form>
                <Stack direction="row" spacing={3} justifyContent={'center'} alignItems={'center'}>
                  <input
                    className="footer-input"
                    name="email"
                    type="email"
                    placeholder="Your Email"
                  />
                  <Button variant="contained" color="secondary">
                    SUBSCRIBE
                  </Button>
                </Stack>
                <Typography as="p" variant="caption" mt="4px" textAlign={'left'} sx={{ opacity: 0.7 }}>
                  You can unsubscribe at any time.
                </Typography>
              </form>
            </Grid> */}
          </Grid>
        </section>
        <section className="footer-links">
          <Grid container spacing={4}>
            <Grid item md={5} hidden={!isDesktop}>
              <Link to="/" className="footerNew-logo">
                <img src={NewLogo} alt="AiMotors logo" />
              </Link>
              <Typography as={'p'} variant="caption" mt={2}>
              </Typography>
            </Grid>
            <Grid item container md={7} rowSpacing={{ xs: 4, md: 0 }} justifyContent="flex-end">
              {
                footerLinks.slice(0, 3).map((item, index) => (
                  <Grid item xs={12} md={4} key={index}>
                    <Stack spacing={2} alignItems={isDesktop ? "flex-end" : "flex-start"}>
                      <Typography variant="subtitle2" color="white" fontWeight={600} sx={{ opacity: 0.6 }}>
                        {item.title}
                      </Typography>
                      <Stack spacing={1} className="footer-link-items" alignItems={isDesktop ? "flex-end" : "flex-start"}>
                        {
                          item.links.map((link, j) => (
                            link.href.startsWith('http') ?
                              <a key={`${index}-${j}`} href={link.href} target="_blank" rel="noopener noreferrer">{link.label}</a> :
                              <Link key={`${index}-${j}`} to={link.href}>{link.label}</Link>
                          ))
                        }
                      </Stack>
                    </Stack>
                  </Grid>
                ))
              }
            </Grid>
          </Grid>
        </section>
        <section className="social-media">
          <div className="social-media-wrap">
            <Grid container>
              <Grid item xs={12} sm={6}>
                <small className="website-rights">© 2024. All right reserved.</small>
              </Grid>
              <Grid item xs={12} sm={6} mt={{ xs: 1, sm: 0 }}>
                <Stack className="social-icons" direction="row" spacing={4} ml={'auto'} justifyContent={'end'}>
                  <a
                    className="social-icon-link instagram"
                    href="https://www.instagram.com/aimotor.kart/"
                    target="_blank"
                    aria-label="Instagram"
                    rel="noopener noreferrer"
                  >
                    <i className="fab fa-instagram" />
                  </a>
                  <a
                    className="social-icon-link facebook"
                    href="https://www.facebook.com/profile.php?id=61554863766530"
                    target="_blank"
                    aria-label="Facebook"
                    rel="noopener noreferrer"
                  >
                    <i className="fab fa-facebook-f" />
                  </a>
                  {/* <Link
                    className="social-icon-link youtube"
                    to="/"
                    target="_blank"
                    aria-label="Youtube"
                  >
                    <i className="fab fa-youtube" />
                  </Link>
                  <Link
                    className="social-icon-link twitter"
                    to="/"
                    target="_blank"
                    aria-label="Twitter"
                  >
                    <i className="fab fa-twitter" />
                  </Link>
                  <Link
                    className="social-icon-link twitter"
                    to="/"
                    target="_blank"
                    aria-label="LinkedIn"
                  >
                    <i className="fab fa-linkedin" /> 
                  </Link> */}
                </Stack>
              </Grid>
            </Grid>
          </div>
        </section>
      </Stack>
    </div>
  );
}

export default Footer;